import Dialog from "@mui/material/Dialog";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {UserData} from "../../../api/CloudLicensesManagerApi";

export const DeleteUserDialog: FunctionComponent<DeleteUserProps> = props => {
    const {t} = useTranslation();
    const {user, onCancelClick, onOkClick} = props;

    return <Dialog
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        onClose={() => props.onCancelClick()}
        open={true}>
        <DialogTitle id="confirmation-dialog-title">{t("Delete user")}</DialogTitle>
        <DialogContent dividers>
            {t("deleteUserConfirmMessage", {username: user.username})}
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onOkClick()} color="primary">
                {t("Ok")}
            </Button>
            <Button autoFocus onClick={() => onCancelClick()} color="primary">
                {t("Cancel")}
            </Button>
        </DialogActions>
    </Dialog>;
}

export interface DeleteUserProps {
    user: UserData;

    onCancelClick: () => void;
    onOkClick: () => void;
}