import React, {Fragment, FunctionComponent, useEffect, useState} from "react";
import {PageTemplate} from "../../common/page-template/PageTemplate";
import {useTranslation} from "react-i18next";
import {FormControl, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
    useDeleteApiV1UserMutation,
    usePostApiV1UserMutation,
    usePutApiV1UserMutation,
    UserData
} from "../../../api/CloudLicensesManagerApi";
import {TableSkeleton} from "../../common/skeleton/TableSkeleton";
import {ErrorMessage} from "../../common/error-message/ErrorMessage";
import {SearchInfo} from "../../common/search-info-grid/SearchInfo";
import {UsersTable} from "./UsersTable";
import {DeleteUserDialog} from "./DeleteUserDialog";
import {EditUserDialog} from "./EditUserDialog";
import {
    ProcessStatusNotification,
    toProcessStatus
} from "../../common/process-status-notification/ProcessStatusNotification";
import {ActionButton} from "../../common/page-template/ActionButton";
import AddIcon from '@mui/icons-material/Add';

export const UsersManagement: FunctionComponent = () => {
    const {t} = useTranslation();
    const [state, setState] = useState<UsersManagementState>({
        currentPage: 0,
        pageSize: 50,
        search: ''
    });
    const [searchUsers, {
        data: usersData,
        isLoading: isUsersLoading,
        isError: isUsersError,
        fulfilledTimeStamp,
        startedTimeStamp
    }] = usePostApiV1UserMutation();
    const [deleteUser, {
        data: deleteUserData,
        isLoading: isDeleteUserLoading,
        isError: isDeleteUserError,
        reset: resetDeleteUser
    }] = useDeleteApiV1UserMutation();
    const [editUser, {
        data: editUserData,
        isLoading: isEditUserLoading,
        isError: isEditUserError,
        reset: resetEditUser
    }] = usePutApiV1UserMutation();

    const callSearchUsers = () => searchUsers({
        searchUsersRequest: {
            search: state.search.length ? state.search : null,
            pageSize: state.pageSize,
            page: state.currentPage,
        }
    });

    useEffect(() => {
        callSearchUsers();
    }, [state.search, state.pageSize, state.currentPage]);

    const UserSearchInfoGrid = () => <SearchInfo
        executionTime={(fulfilledTimeStamp ?? 0) - (startedTimeStamp ?? 0)}
        count={usersData!.payload!.count ?? 0}
        currentPage={state.currentPage}
        pageSize={state.pageSize}
        onPageChange={page => {
            setState(state => ({...state, currentPage: page}));
        }}
        onPageSizeChange={pageSize => {
            setState(state => ({...state, pageSize}));
        }}
        paginationLabel={t("Users per page")}
        itemsLabel={t("users")}/>;

    return <PageTemplate pageTitle={t("Users management")}
                         actions={[
                             <ActionButton key={`action-button-add-user`}
                                           label={t("Add user")}
                                           onClick={() => {
                                               setState({...state, userToEdit: {} as UserData});
                                           }}
                                           icon={<AddIcon/>}/>
                         ]}>
        <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="search-user">{t("Search by email…")}</InputLabel>
            <OutlinedInput
                id="search-user"
                value={state.search}
                onChange={e => setState({...state, search: e.target.value})}
                label={t("Search by email…")}
                endAdornment={<InputAdornment position="end"><SearchIcon/></InputAdornment>}/>
        </FormControl>

        {isUsersLoading &&
            <TableSkeleton sx={{marginTop: '35px'}}
                           rowCount={10}
                           colCount={5}
                           colCountSm={4}
                           colCountXs={2}
                           showPagination/>}

        {isUsersError && <ErrorMessage
            message={t("An error occurred while loading users, please contact the administrator or try again")}/>}

        {Boolean(usersData && usersData.payload && usersData.payload.users && usersData.payload.users.length) &&
            <Fragment>
                <UserSearchInfoGrid/>

                <UsersTable users={usersData?.payload?.users ?? []}
                            onDelete={(userToDelete) => {
                                setState(state => ({...state, userToDelete}));
                            }}
                            onEdit={(userToEdit) => {
                                setState(state => ({...state, userToEdit}));
                            }}/>

                <UserSearchInfoGrid/>
            </Fragment>}

        {state.userToDelete &&
            <DeleteUserDialog user={state.userToDelete}
                              onCancelClick={() => {
                                  setState(state => ({
                                      ...state,
                                      userToDelete: undefined
                                  }));
                              }}
                              onOkClick={() => {
                                  if (state.userToDelete) {
                                      deleteUser({
                                          deleteUserRequest: {
                                              userId: state.userToDelete.id
                                          }
                                      }).then(() => {
                                          if (state.currentPage !== 0) {
                                              setState(state => ({...state, currentPage: 0}));
                                          } else {
                                              callSearchUsers();
                                          }

                                          resetDeleteUser();
                                      })
                                      setState(state => ({
                                          ...state,
                                          userToDelete: undefined
                                      }));
                                  }
                              }}/>}

        {state.userToEdit &&
            <EditUserDialog user={state.userToEdit}
                            onCancelClick={() => {
                                setState(state => ({
                                    ...state,
                                    userToEdit: undefined
                                }));
                            }}
                            onOkClick={(role, userId, username, password) => {
                                if (state.userToEdit) {
                                    editUser({
                                        updateUserRequest: {
                                            role,
                                            userId,
                                            username,
                                            password
                                        }
                                    }).then(() => {
                                        callSearchUsers();

                                        resetEditUser();
                                    })
                                    setState(state => ({
                                        ...state,
                                        userToEdit: undefined
                                    }));
                                }
                            }}/>}

        <ProcessStatusNotification
            status={toProcessStatus(isDeleteUserLoading, Boolean(deleteUserData?.payload), isDeleteUserError)}
            onClose={() => resetDeleteUser()}
            onLoadingMessage={t("Deleting user please wait…")}
            onSuccessMessage={t("User deleted successfully")}
            onErrorMessage={t("User deleting failed")}/>

        <ProcessStatusNotification
            status={toProcessStatus(isEditUserLoading, Boolean(editUserData?.payload), isEditUserError)}
            onClose={() => resetEditUser()}
            onLoadingMessage={t("Updating user please wait…")}
            onSuccessMessage={t("User updated successfully")}
            onErrorMessage={t("User updating failed")}/>
    </PageTemplate>
}

interface UsersManagementState {
    currentPage: number;
    pageSize: number;
    search: string;
    userToDelete?: UserData;
    userToEdit?: UserData;
}