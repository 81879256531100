import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";

export const PageSizeSelector = styled(Box)(() => ({
    display: 'inline-block',
    marginRight: '35px',

    '& span': {
        paddingLeft: '15px'
    }
}));