import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {ProductData} from "../../../api/CloudLicensesManagerApi";
import {DateUtils} from "../../../book/DateUtils";

export const ProductInfo: FunctionComponent<ProductInfoProps> = props => {
    const {t} = useTranslation();
    const serialNumberLife = DateUtils.secondsToYMDHMS(props.product.serialNumberLife);

    return <ul style={{padding: '0 0 0 18px', margin: 0}}>
        <li>
            <b>{t("Name")}:</b> {props.product.name}
        </li>
        <li>
            <b>{t("Description")}:</b> {props.product.description}
        </li>
        <li>
            <b>{t("Product type name")}:</b> {props.product.productTypeName}</li>
        <li>
            <b>{t("Product reference")}:</b> {props.product.productReference}
        </li>
        <li>
            <b>{t("App key")}:</b> {props.product.appKey}
        </li>
        <li>
            <b>{t("Serial number TTL")}:</b>&nbsp;
            {serialNumberLife?.years ? `${serialNumberLife?.years} ${t("years")} ` : ''}
            {serialNumberLife?.days ? `${serialNumberLife?.days} ${t("days")} ` : ''}
            {serialNumberLife?.hours ? `${serialNumberLife?.hours} ${t("hours")} ` : ''}
            {serialNumberLife?.minutes ? `${serialNumberLife?.minutes} ${t("minutes")} ` : ''}
            {serialNumberLife?.seconds ? `${serialNumberLife?.days} ${t("seconds")}` : ''}
        </li>
    </ul>;
}

export interface ProductInfoProps {
    product: ProductData;
}