import React, {FunctionComponent} from "react";
import {PageTemplate} from "../../common/page-template/PageTemplate";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {ProductInfo} from "./ProductInfo";
import {ErrorMessage} from "../../common/error-message/ErrorMessage";
import {DottedListSkeleton} from "../../common/skeleton/DottedListSkeleton";
import {useGetApiV1ProductQuery} from "../../../api/CloudLicensesManagerApi";
import {TableSkeleton} from "../../common/skeleton/TableSkeleton";
import {DateUtils} from "../../../book/DateUtils";

export const ProductDetails: FunctionComponent = () => {
    const {productId} = useParams();
    const {t} = useTranslation();
    const {
        data: productData,
        isLoading: isProductLoading,
        isError: isProductError
    } = useGetApiV1ProductQuery({
        productId
    });

    const product = productData?.payload?.product;
    const features = productData?.payload?.features ?? [];

    return <PageTemplate pageTitle={`${t("Product")} - ${product?.name}`} showBackButton>
        {isProductError && <ErrorMessage
            message={t("Error while load order, please check product id and retry.")}/>}

        {isProductLoading && <DottedListSkeleton rowCount={7}/>}

        {Boolean(product) && <ProductInfo product={product!}/>}

        {!Boolean(product) && !isProductLoading &&
            <ErrorMessage
                message={t("Error while loading product details, please return to product list and try again.")}/>}

        {isProductLoading &&
            <TableSkeleton sx={{marginTop: '40px'}}
                           colCount={3}
                           rowCount={10}/>}

        {Boolean(features.length) && <Table>
            <TableHead>
                <TableRow>
                    <TableCell>{t("Feature name")}</TableCell>
                    <TableCell>{t("Feature description")}</TableCell>
                    <TableCell>{t("Feature TTL")}</TableCell>
                    <TableCell>{t("Feature creation")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {features.map(feature => {
                    const featureLife = DateUtils.secondsToYMDHMS(feature.expiredAt ?? undefined);

                    return <TableRow key={feature.id}>
                        <TableCell>{feature.name}</TableCell>
                        <TableCell>{feature.description}</TableCell>
                        <TableCell>
                            {featureLife?.years ? `${featureLife?.years} ${t("years")}` : ''}
                            {featureLife?.days ? `${featureLife?.days} ${t("days")}` : ''}
                            {featureLife?.hours ? `${featureLife?.hours} ${t("hours")}` : ''}
                            {featureLife?.minutes ? `${featureLife?.minutes} ${t("minutes")}` : ''}
                            {featureLife?.seconds ? `${featureLife?.days} ${t("seconds")}` : ''}
                        </TableCell>
                        <TableCell>{feature.inserted ? DateUtils.dateTimeFormat(feature.inserted) : '-'}</TableCell>
                    </TableRow>;
                })}
            </TableBody>
        </Table>}

    </PageTemplate>;
}