import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, {FunctionComponent} from "react";
import {SnackbarCloseReason} from "@mui/material/Snackbar/Snackbar";
import {AlertColor} from "@mui/material/Alert/Alert";

export type ProcessStatus = 'isLoading' | 'failed' | 'successful' | 'none';

export const toProcessStatus = (isLoading?: boolean, isSuccessful?: boolean, isFailed?: boolean): ProcessStatus => {
    if (isLoading) {
        return 'isLoading';
    }

    if (isSuccessful) {
        return 'successful';
    }

    if (isFailed) {
        return 'failed';
    }

    return 'none';
}

export const ProcessStatusNotification: FunctionComponent<ProcessStatusNotificationProps> = props => {
    const {onErrorMessage, onLoadingMessage, onSuccessMessage, status} = props;
    const autoHideDuration = props.autoHideDuration ?? 5000;

    const onClose = (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }

        props.onClose();
    }

    const alertProps = ((): { severity?: AlertColor, children?: string } => {
        switch (status) {
            case "failed":
                return {severity: "error", children: onErrorMessage};
            case "isLoading":
                return {severity: "info", children: onLoadingMessage};
            case "successful":
                return {severity: "success", children: onSuccessMessage};
            default:
                return {};
        }
    })();

    return <Snackbar open={status !== 'none'}
                     autoHideDuration={autoHideDuration}
                     onClose={onClose}>
        <Alert role="message" onClose={onClose} {...alertProps} />
    </Snackbar>;
}

export interface ProcessStatusNotificationProps {
    status: ProcessStatus;
    onClose: () => void;
    onLoadingMessage: string;
    onSuccessMessage: string;
    onErrorMessage: string;
    autoHideDuration?: number;
}