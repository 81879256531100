import {UserState} from "../entity/UserState";
import {createReducer} from "@reduxjs/toolkit";
import {userLoginAction} from "../action/UserLoginAction";
import {userLogoutAction} from "../action/UserLogoutAction";

export const userStateReducer = createReducer({} as UserState, (builder) => {
    builder.addCase(userLoginAction, (state, action) => ({
        ...state,
        token: action.payload.token,
        tokenExpirationDate: action.payload.tokenExpirationDate,
    }))
    builder.addCase(userLogoutAction, (state, action) => ({
        ...state,
        token: undefined,
        tokenExpirationDate: undefined,
    }))
})