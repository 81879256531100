import {styled} from "@mui/material/styles";
import {Paper} from "@mui/material";

export const Children = styled(Paper)(({theme}) => ({
    padding: '30px 20px',
    overflow: 'hidden',
    maxWidth: '1200px',
    margin: '2em auto 0px auto',
    [theme.breakpoints.down('sm')]: {
        padding: '15px 10px',
        boxShadow: 'none',
        marginTop: 0
    },
}))