import {combineReducers, configureStore, ConfigureStoreOptions} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";
import {cloudLicensesManagerApi} from "../api/CloudLicensesManagerApi";
import {userStateReducer} from "./UserStateReducer";

const reducers = combineReducers({
    [cloudLicensesManagerApi.reducerPath]: cloudLicensesManagerApi.reducer,
    userState: userStateReducer,
})

const persistConfig = {
    key: 'clim-state',
    storage,
    whitelist: ['userState']
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
    configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
            }).concat(cloudLicensesManagerApi.middleware) as any,
        ...options,
    })

export const appStore = createStore()

export type AppDispatch = typeof appStore.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export type AppState = ReturnType<typeof appStore.getState>
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
export const storePersistor = persistStore(appStore);
