import React, {FunctionComponent} from 'react';
import {Box, Grid, Skeleton, Theme} from "@mui/material";
import {SxProps} from "@mui/system";

export const PaginationSkeleton: FunctionComponent<PaginationSkeletonProps> = props => {
    return <Box sx={props.sx} role="skeleton-pagination">
        <Box sx={{minWidth: '150px'}}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={3}>
                    <Skeleton variant="text" width={200}/>
                </Grid>
                <Grid item xs={2} sm={5} md={3}/>
                <Grid item xs={8} sm={5} md={4}>
                    <Skeleton variant="text"/>
                </Grid>
                <Grid item xs={2} sm={2} md={2} sx={{textAlign: 'center'}}>
                    <Skeleton variant="rounded" width={32} height={32} sx={{display: 'inline-block'}}/>
                </Grid>
            </Grid>
        </Box>
    </Box>;
}

export interface PaginationSkeletonProps {
    sx?: SxProps<Theme>;
}