import React, {FunctionComponent} from "react";
import {Box, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Theme, useTheme} from "@mui/material";
import {PaginationSkeleton} from "./PaginationSkeleton";
import {styled} from "@mui/material/styles";
import {SxProps} from "@mui/system";

const StyledPaginationSkeleton = styled(PaginationSkeleton)(({theme}) => ({
    flexGrow: 1,
    marginTop: '1.5em',
    [theme.breakpoints.down('sm')]: {
        marginLeft: '0.5em',
        marginTop: '0.5em',
        textAlign: 'right'
    },
}));

export const TableSkeleton: FunctionComponent<TableSkeletonProps> = props => {
    const {
        showPagination,
        colCount,
        rowCount,
        colCountMd,
        colCountSm,
        colCountXs,
    } = props;
    const theme = useTheme();

    return <Box sx={props.sx}>
        {showPagination && <StyledPaginationSkeleton/>}
        <Table aria-label="users">
            {props.hideHeader !== true && <TableHead>
                <TableRow role="skeleton-header-row">
                    {Array(colCount).fill("skeleton-head-cell").map((v, j) => (
                        <TableCell key={`${v}-${j}`}
                                   sx={{
                                       [theme.breakpoints.down('xs')]: {
                                           display: colCountXs && j >= colCountXs ? 'none' : 'table-cell'
                                       },
                                       [theme.breakpoints.down('sm')]: {
                                           display: colCountSm && j >= colCountSm ? 'none' : 'table-cell'
                                       },
                                       [theme.breakpoints.down('md')]: {
                                           display: colCountMd && j >= colCountMd ? 'none' : 'table-cell'
                                       },
                                   }}
                                   role="skeleton-header-cell">
                            <Skeleton variant="text"/>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>}
            <TableBody>
                {Array(rowCount).fill("skeleton-body-row").map((v, j) => <TableRow key={`${v}-${j}`} role="skeleton-body-row">
                    {Array(colCount).fill("skeleton-body-cell").map((x, i) =>
                        <TableCell key={`${x}-${i}`}
                                   sx={{
                                       [theme.breakpoints.down('xs')]: {
                                           display: colCountXs && j >= colCountXs ? 'none' : 'table-cell'
                                       },
                                       [theme.breakpoints.down('sm')]: {
                                           display: colCountSm && j >= colCountSm ? 'none' : 'table-cell'
                                       },
                                       [theme.breakpoints.down('md')]: {
                                           display: colCountMd && j >= colCountMd ? 'none' : 'table-cell'
                                       },
                                   }}
                                   role="skeleton-body-cell">
                            <Skeleton variant="text"/>
                        </TableCell>
                    )}
                </TableRow>)}
            </TableBody>
        </Table>
        {showPagination && <StyledPaginationSkeleton/>}
    </Box>;
}

export interface TableSkeletonProps {
    showPagination?: boolean;
    hideHeader?: boolean;
    rowCount: number;
    colCount: number;
    colCountMd?: number;
    colCountSm?: number;
    colCountXs?: number;
    sx?: SxProps<Theme>;
}