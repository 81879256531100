import {IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import {DateUtils} from "../../../book/DateUtils";
import {Link} from "react-router-dom";
import {AppRoute} from "../../../book/AppRoute";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {
    ActivationItem,
    useGetApiV1UserMeQuery,
    usePostApiV1ProductLicenseMutation
} from "../../../api/CloudLicensesManagerApi";
import {FileDownload} from "@mui/icons-material";
import {UserRole, UserRoleUtils} from "../../../book/UserRoleUtils";
import {ProcessStatusNotification, toProcessStatus} from "../process-status-notification/ProcessStatusNotification";
import {DownloadLicenseFile} from "../utils/CreateLicenseFile";
import DeleteIcon from '@mui/icons-material/Delete';

export const ActivationsTable: FunctionComponent<ActivationsTableProps> = props => {
    const {t} = useTranslation();
    const {data: userMe} = useGetApiV1UserMeQuery(undefined);
    const [productLicense, {
        data: productLicenseData,
        isLoading: isProductLicenseLoading,
        error: productLicenseError,
        reset: resetProductLicense
    }] = usePostApiV1ProductLicenseMutation();

    return <Table size="small">
        <ProcessStatusNotification
            status={toProcessStatus(isProductLicenseLoading, Boolean(productLicenseData?.payload), Boolean(productLicenseError))}
            onClose={() => resetProductLicense()}
            onLoadingMessage={t("Creating license.jlic file please wait…")}
            onSuccessMessage={t("license.jlic file created successfully")}
            onErrorMessage={t("Creating license.jlic file failed")}/>

        <TableHead>
            <TableRow>
                <TableCell>{t("Serial number")}</TableCell>
                <TableCell>{t("Company")}</TableCell>
                <TableCell>{t("Country")}</TableCell>
                <TableCell>{t("Activation date")}</TableCell>
                <TableCell>
                    {t("Last check date")}
                    <sup>
                        <Tooltip title={`${t("Last execution of license period check")}`}
                                 aria-label={t("Last execution of license period check")}>
                            <HelpIcon fontSize="inherit"/>
                        </Tooltip>
                    </sup>
                </TableCell>
                <TableCell/>
            </TableRow>
        </TableHead>
        <TableBody>
            {props.activations.map(activation =>
                <TableRow key={activation.activationId}>
                    <TableCell title={activation.serialNumber}>
                        {activation.serialNumber}
                    </TableCell>
                    <TableCell>{activation.company}</TableCell>
                    <TableCell>{activation.country}</TableCell>
                    <TableCell>{DateUtils.dateTimeFormat(activation.activationDate)}</TableCell>
                    <TableCell>{DateUtils.dateTimeFormat(activation.lastCheckDate)}</TableCell>
                    <TableCell>
                        {UserRoleUtils.ToRole(userMe?.payload?.role) === UserRole.Admin && <IconButton
                            onClick={async () => {
                                try {
                                    const result = await productLicense({
                                        productLicenseRequest: {
                                            hardwareId: activation?.hardwareId,
                                            serialNumber: activation?.serialNumber,
                                        }
                                    });
                                    if ("data" in result && result.data?.payload?.jwtLicense) {
                                        DownloadLicenseFile(result.data.payload.jwtLicense);
                                    }
                                } catch (e) {
                                    console.log(e);
                                }
                            }}
                            title={t("Download .jlic file")}>
                            <FileDownload/>
                        </IconButton>}
                        <IconButton
                            onClick={() => props.onFreeActivationClick(activation)}
                            title={t("Free serial number")}>
                            <DeleteIcon/>
                        </IconButton>
                        <IconButton
                            component={Link}
                            to={AppRoute.Activation.replace(":orderId", activation.orderId).replace(":activationId", activation.activationId)}>
                            <NavigateNextIcon/>
                        </IconButton>
                    </TableCell>
                </TableRow>)}
        </TableBody>
    </Table>
}

export interface ActivationsTableProps {
    activations: ActivationItem[];
    onFreeActivationClick: (activation: ActivationItem) => void;
}