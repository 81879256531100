export const UserRole = {
    Unknown: -1,
    User: 10,
    Admin: 40,
};

export class UserRoleUtils {
    public static UserRoleMap: { [key: string]: number } = {
        'User': UserRole.User,
        'Admin': UserRole.Admin,
    };

    public static ToRole = (role?: string | null): number => {
        return role ? UserRoleUtils.UserRoleMap[role] as number : UserRole.Unknown;
    }

    public static ToRoleName = (role: number | null | undefined): string => {
        switch (role) {
            case UserRole.User:
                return "User";
            case UserRole.Admin:
                return "Admin";
            default:
                return "Boh!";
        }
    }
}