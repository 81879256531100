import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../store/AppStore";
import {Menu, MenuItem} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {userLogoutAction} from "../../../action/UserLogoutAction";

export const AppbarUserMenu: FunctionComponent<AppbarUserMenuProps> = props => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    return <Menu
        anchorEl={props.anchorEl}
        open={props.isOpen}
        keepMounted
        onClose={props.handleClose}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
        <MenuItem onClick={() => {
            dispatch(userLogoutAction());
        }}>
            <ListItemIcon>
                <ExitToAppIcon/>
            </ListItemIcon>
            <Typography variant="inherit">{t("Sign out")}</Typography>
        </MenuItem>
    </Menu>;
}

export interface AppbarUserMenuProps {
    isOpen: boolean;
    anchorEl: HTMLElement | null;
    handleClose: () => void;
}