import React, {FunctionComponent} from "react";
import {Button, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {DateUtils} from "../../../book/DateUtils";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";
import {UserActivityLogData, UserActivityLogType, UserData} from "../../../api/CloudLicensesManagerApi";

const maxDataLength = 300;

export const UserActivityLogTable:FunctionComponent<UserActivityLogTableProps>=props=>{
    const {t} = useTranslation();
    const {userActivities,users,onCopyClick,operations}=props;

    return <Table aria-label="products">
        <TableHead>
            <TableRow>
                <TableCell>{t("User")}</TableCell>
                <TableCell>{t("Operation")}</TableCell>
                <TableCell>{t("Data")}</TableCell>
                <TableCell/>
                <TableCell>{t("Date time")}</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {userActivities.map(userActivity => {
                const serialNumberLife = DateUtils.dateTimeFormat(userActivity.inserted!);
                const userActivityData = JSON.stringify(JSON.parse((userActivity.data ?? ''), (_, value) => value === null ? undefined : value))
                    .replaceAll(",", ", ")
                    .replaceAll(":", ": ")
                    .replaceAll("{", " { ")
                    .replaceAll("[", " [ ");

                return <TableRow key={userActivity.id}>
                    <TableCell
                        scope="row">{users.find(u => u.id === userActivity.userId)?.username}</TableCell>
                    <TableCell>{operations.find(o=>o.code===userActivity.operation)?.name}</TableCell>
                    <TableCell>
                        <Tooltip title={userActivityData} sx={{flex: 1, overflowWrap: 'anywhere'}}>
                            <Typography>
                                {userActivityData.length > maxDataLength ? `${userActivityData.substring(0, maxDataLength)}...` : userActivityData}
                            </Typography>
                        </Tooltip>
                    </TableCell>
                    <TableCell>
                        <Button title={t("Copy data to clipboard")}
                                variant="contained"
                                size="small"
                                onClick={async () => {
                                    await navigator.clipboard.writeText(userActivity.data!);
                                    onCopyClick();
                                    // setState(state => ({
                                    //     ...state,
                                    //     openCopiedToClipboardSnackbar: true
                                    // }));
                                }}>
                            {t("Copy")}
                        </Button>
                    </TableCell>
                    <TableCell>{serialNumberLife}</TableCell>
                </TableRow>
            })}
        </TableBody>
    </Table>;
}

export interface UserActivityLogTableProps{
    userActivities: UserActivityLogData[];
    users: UserData[];
    operations: { code: UserActivityLogType, name: string }[];
    onCopyClick: ()=>void;
}