import React, {FunctionComponent, useEffect, useState} from "react";
import {PropData} from "../../../api/CloudLicensesManagerApi";
import {Box, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Stack} from "@mui/material";
import {LicensePropEditorProxy} from "./license-prop-editors/LicensePropEditorProxy";
import {LicensePropKeys} from "./book/LicensePropKeys";
import {useTranslation} from "react-i18next";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Typography from "@mui/material/Typography";
import {SxProps} from "@mui/system/styleFunctionSx";

export const LicensePropsEditor: FunctionComponent<PropsEditorProps> = props => {
    const [errors, setErrors] = useState<boolean[]>([]);
    const {t} = useTranslation();

    const handlePropKeyChange = (index: number, newKey: string) => {
        const updatedValues = [...props.licenseProps];
        updatedValues[index] = {...(updatedValues[index]), key: newKey};
        props.onPropsChange(updatedValues);
    };

    const handlePropValueChange = (index: number, newValue: string) => {
        const updatedValues = [...props.licenseProps];
        updatedValues[index] = {...(updatedValues[index]), value: newValue};
        props.onPropsChange(updatedValues);
    };

    const handleRemoveProp = (index: number) => {
        const updatedValues = [...props.licenseProps];
        updatedValues.splice(index, 1);
        props.onPropsChange(updatedValues);
    };

    const handleAddProp = () => {
        const updatedValues = [...props.licenseProps, {key: ''} as PropData];
        props.onPropsChange(updatedValues);
    }

    const onErrorChange = (index: number, error: boolean) => {
        const updatedValues = [...errors];
        updatedValues[index] = error;
        setErrors(updatedValues);
    }

    useEffect(() => {
        let error = false;
        for (let i = 0; i < props.licenseProps.length; i++) {
            error = error || errors[i] || !props.licenseProps[i].key || props.licenseProps[i].key === '';
        }
        props.onErrorChange(error);
    }, [errors, props.licenseProps]);

    return <Box sx={{...props.sx}}>
        <Typography variant="h6">
            Props

            <IconButton onClick={() => handleAddProp()} color="primary" data-testid="add-button">
                <AddCircleIcon/>
            </IconButton>
        </Typography>

        {props.licenseProps.length === 0 && <div>No props to display</div>}

        {props.licenseProps.map((licenseProp, index) => {
            return <Stack direction='row' key={`prop-editor-${index}`} sx={{marginTop: '0.6em'}} spacing={1}>
                <Box>
                    <FormControl>
                        <InputLabel id={`prop-label-${index}`}>{t("Prop key")}</InputLabel>
                        <Select labelId={`prop-label-${index}`}
                                error={!licenseProp.key || licenseProp.key.trim() === ''}
                                value={licenseProp.key ?? ''}
                                label={t("Prop key")}
                                sx={{
                                    minWidth: '230px'
                                }}
                                onChange={e => {
                                    handlePropKeyChange(index, e.target.value);
                                }}>
                            <MenuItem value="">
                                <em>{t('None')}</em>
                            </MenuItem>
                            {Object.entries(LicensePropKeys).map(propKeyValue =>
                                <MenuItem value={propKeyValue[1]}
                                          key={`license-prop-key-${propKeyValue[1]}`}>
                                    {t(propKeyValue[1])}
                                </MenuItem>
                            )}
                        </Select>
                        {!Boolean(licenseProp.key) && <FormHelperText>{t('Choose a prop key')}</FormHelperText>}
                    </FormControl>
                </Box>

                <LicensePropEditorProxy propValue={licenseProp.value ?? ''}
                                        onChange={(e) => handlePropValueChange(index, e)}
                                        propKey={licenseProp.key ?? ''}
                                        onErrorChange={(error) => onErrorChange(index, error)}/>

                <IconButton onClick={() => handleRemoveProp(index)} color="primary" data-testid="remove-button">
                    <RemoveCircleIcon/>
                </IconButton>
            </Stack>;
        })}
    </Box>;
}

export interface PropsEditorProps {
    licenseProps: PropData[];
    onPropsChange: (newProps: PropData[]) => void;
    sx?: SxProps;
    onErrorChange: (error: boolean) => void;
}