import React, {FunctionComponent} from "react";
import {FormControl, InputLabel, MenuItem, Select, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {UserData} from "../../../api/CloudLicensesManagerApi";

export const UserActivityLogFilters: FunctionComponent<UserActivityLogFiltersProps> = props => {
    const {t} = useTranslation();
    const {
        isUsersLoading,
        onUserIdChange,
        isUsersError,
        userId,
        onOperationChange,
        operations,
        operationCode,
        users
    } = props;

    return <Stack direction="row" spacing={1}>
        {(!isUsersLoading && !isUsersError) && <FormControl fullWidth>
            <InputLabel id="user-select-label">User</InputLabel>
            <Select labelId="user-select-label"
                    value={userId}
                    label={t("User activity")}
                    onChange={e => onUserIdChange(e.target.value)}>
                <MenuItem value="">{t("None")}</MenuItem>
                {users.map(user =>
                    <MenuItem key={`user-id-${user.id}`}
                              value={user.id}>{user.username}</MenuItem>)}
            </Select>
        </FormControl>}

        <FormControl fullWidth>
            <InputLabel id="operation-select-label">{t("Operation")}</InputLabel>
            <Select labelId="operation-select-label"
                    value={operationCode}
                    label={t("User operation")}
                    onChange={e => onOperationChange("" + e.target.value)}>
                <MenuItem value={""}>{t("None")}</MenuItem>
                {operations.map(operation =>
                    <MenuItem key={`user-id-${operation.code}`}
                              value={operation.code}>{operation.name}</MenuItem>)}
            </Select>
        </FormControl>
    </Stack>
}

export interface UserActivityLogFiltersProps {
    isUsersLoading: boolean;
    isUsersError: boolean;
    userId: string;
    users: UserData[];
    onUserIdChange: (userId: string) => void;

    operationCode: string;
    operations: { code: number, name: string }[];
    onOperationChange: (operation: string) => void;
}