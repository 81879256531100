import React, {FunctionComponent, useEffect, useState} from "react";
import {PageTemplate} from "../../common/page-template/PageTemplate";
import {useTranslation} from "react-i18next";
import {usePostApiV1ProductMutation} from "../../../api/CloudLicensesManagerApi";
import {SearchInfo} from "../../common/search-info-grid/SearchInfo";
import {
    FormControl, IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {ErrorMessage} from "../../common/error-message/ErrorMessage";
import {TableSkeleton} from "../../common/skeleton/TableSkeleton";
import {DateUtils} from "../../../book/DateUtils";
import {Link} from "react-router-dom";
import {AppRoute} from "../../../book/AppRoute";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export const ProductList: FunctionComponent = () => {
    const {t} = useTranslation();
    const [state, setState] = useState<ProductListState>({
        currentPage: 0,
        pageSize: 50,
        search: ''
    });
    const [productsSearch, {
        data: productsSearchData,
        isLoading: isProductsLoading,
        isError: isProductsError
    }] = usePostApiV1ProductMutation();

    const searchResultProducts = productsSearchData?.payload?.products ?? [];
    const searchResultCount = productsSearchData?.payload?.count ?? 0;

    const callProductsSearch = () => {
        productsSearch({
            searchProductsRequest: {
                search: state.search.length ? state.search : null,
                page: state.currentPage,
                pageSize: state.pageSize
            }
        });
    }

    useEffect(() => {
        callProductsSearch();
    }, [state.search, state.currentPage, state.pageSize]);

    const ProductsSearchInfoGrid = () => <SearchInfo
        count={searchResultCount}
        currentPage={state.currentPage}
        pageSize={state.pageSize}
        onPageChange={page => {
            setState(state => ({...state, currentPage: page}));
            callProductsSearch();
        }}
        onPageSizeChange={pageSize => {
            setState(state => ({...state, pageSize}));
            callProductsSearch();
        }}
        paginationLabel={t("Products per page")}
        itemsLabel={t("products")}/>;

    return <PageTemplate pageTitle="Products">
        <FormControl fullWidth variant="outlined">
            <InputLabel
                htmlFor="search-products">{t("Search product by name or description…")}</InputLabel>
            <OutlinedInput value={state.search}
                           fullWidth
                           label={t("Search product by name, product type name, or description…")}
                           sx={{marginBottom: '20px'}}
                           onChange={e => {
                               setState(state => ({...state, search: e.target.value}))
                           }}
                           endAdornment={<InputAdornment position="end"><SearchIcon/></InputAdornment>}/>
        </FormControl>

        {isProductsError && <ErrorMessage
            message={t("Error while loading orders. Please try again or contact the administrator")}/>}

        {isProductsLoading &&
            <TableSkeleton sx={{marginTop: '40px'}}
                           colCount={5}
                           rowCount={10}
                           showPagination/>}

        {Boolean(searchResultProducts?.length) && <React.Fragment>
            <ProductsSearchInfoGrid/>

            <Table aria-label="products">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("Name")}</TableCell>
                        <TableCell>{t("Description")}</TableCell>
                        <TableCell>{t("Product type name")}</TableCell>
                        {/*<TableCell>{t("Activation TTL")}</TableCell>*/}
                        <TableCell>{t("Serial number TTL")}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {searchResultProducts.map(product => {
                        const serialNumberLife = DateUtils.secondsToYMDHMS(product.serialNumberLife);

                        return <TableRow key={product.id}>
                            <TableCell scope="row">{product.name}</TableCell>
                            <TableCell>{product.description}</TableCell>
                            <TableCell>{product.productTypeName}</TableCell>
                            <TableCell>
                                {serialNumberLife?.years ? `${serialNumberLife?.years} ${t("years")} ` : ''}
                                {serialNumberLife?.days ? `${serialNumberLife?.days} ${t("days")} ` : ''}
                                {serialNumberLife?.hours ? `${serialNumberLife?.hours} ${t("hours")} ` : ''}
                                {serialNumberLife?.minutes ? `${serialNumberLife?.minutes} ${t("minutes")} ` : ''}
                                {serialNumberLife?.seconds ? `${serialNumberLife?.days} ${t("seconds")}` : ''}
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    title={t("Product detail")}
                                    size="small"
                                    aria-label={t("Product detail")}
                                    component={Link}
                                    to={`${AppRoute.Product.replace(':productId', product.id)}`}>
                                    <NavigateNextIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>

            <ProductsSearchInfoGrid/>
        </React.Fragment>}
    </PageTemplate>
}

interface ProductListState {
    currentPage: number;
    pageSize: number;
    search: string;
}