import React, {FunctionComponent} from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "@mui/material/Dialog";
import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ActivationItem} from "../../../api/CloudLicensesManagerApi";

export const FreeActivationDialog: FunctionComponent<FreeActivationDialogProps> = props => {
    const {t} = useTranslation();
    const {activation, onCancelClick, onOkClick, open} = props;

    if (!activation) {
        return null;
    }

    return <Dialog maxWidth="sm"
                   aria-labelledby="confirmation-dialog-title"
                   open={open}
                   onClose={() => props.onCancelClick()}>
        <DialogTitle id="confirmation-dialog-title">{t("Free serial number")}</DialogTitle>
        <DialogContent dividers>
            <DialogContentText>
                Are you sure to free serial number <strong>{activation.serialNumber}</strong>?
                <Typography variant="body2" gutterBottom>
                    {t("The user will no longer be allowed to run the application.")}
                </Typography>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onOkClick()} color="primary">
                {t("Ok")}
            </Button>
            <Button autoFocus onClick={() => onCancelClick()} color="primary">
                {t("Cancel")}
            </Button>
        </DialogActions>
    </Dialog>;
}

export interface FreeActivationDialogProps {
    activation?: ActivationItem;
    open: boolean;

    onCancelClick: () => void;
    onOkClick: () => void;
}
