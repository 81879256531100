import React, {Fragment, FunctionComponent, useEffect, useState} from "react";
import {PageTemplate} from "../../common/page-template/PageTemplate";
import {useTranslation} from "react-i18next";
import {Alert, Box, Snackbar} from "@mui/material";
import {
    usePostApiV1UserActivityLogMutation,
    usePostApiV1UserMutation,
    UserActivityLogType
} from "../../../api/CloudLicensesManagerApi";
import {SearchInfo} from "../../common/search-info-grid/SearchInfo";
import {TableSkeleton} from "../../common/skeleton/TableSkeleton";
import {ErrorMessage} from "../../common/error-message/ErrorMessage";
import {WarningMessage} from "../../common/error-message/WarningMessage";
import {UserActivityLogFilters} from "./UserActivityLogFilters";
import {UserActivityLogTable} from "./UserActivityLogTable";

export const UserActivityLog: FunctionComponent = () => {
    const {t} = useTranslation();
    const [state, setState] = useState<UserActivityLogState>({
        currentPage: 0,
        pageSize: 50,
        userId: '',
        openCopiedToClipboardSnackbar: false,
        operationCode: ""
    });
    const [searchUsers, {
        data: usersData,
        isLoading: isUsersLoading,
        isError: isUsersError
    }] = usePostApiV1UserMutation();
    const [userActivityLogSearch, {
        data: userActivityLogData,
        isLoading: userActivityLogLoading,
        isError: userActivityLogError
    }] = usePostApiV1UserActivityLogMutation();

    const searchResultUserActivity = userActivityLogData?.payload?.logs ?? [];
    const searchResultCount = userActivityLogData?.payload?.count ?? 0;

    const callProductsSearch = () => {
        userActivityLogSearch({
            searchUserActivityLogRequest: {
                userId: state.userId.length ? state.userId : null,
                operation: state.operationCode.length ? parseInt(state.operationCode) as UserActivityLogType : undefined,
                page: state.currentPage,
                pageSize: state.pageSize
            }
        });
    }

    useEffect(() => {
        callProductsSearch();
        searchUsers({searchUsersRequest: {page: 0, pageSize: 100}});
    }, [state.userId, state.operationCode, state.currentPage, state.pageSize]);

    const operationName = (operationCode: UserActivityLogType | undefined): string => {
        switch (operationCode) {
            case 0:
                return t("Unknown operation");
            case 1:
                return t("Edit license");
            case 2:
                return t("Create STone internal serial number");
            case 3:
                return t("Delete serial number");
            default:
                return `Operation code ${operationCode}`;
        }
    }

    const operations: { name: string, code: UserActivityLogType }[] = [{
        name: operationName(1),
        code: 1
    }, {
        name: operationName(2),
        code: 2
    }, {
        name: operationName(3),
        code: 3
    }];

    const handleCloseCopiedToClipboardSnackbar = (event?: React.SyntheticEvent<any> | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setState(state => ({...state, openCopiedToClipboardSnackbar: false}));
    };

    const UserActivityLogSearchInfoGrid = () => <SearchInfo
        count={searchResultCount}
        currentPage={state.currentPage}
        pageSize={state.pageSize}
        onPageChange={page => {
            setState(state => ({...state, currentPage: page}));
            callProductsSearch();
        }}
        onPageSizeChange={pageSize => {
            setState(state => ({...state, pageSize}));
            callProductsSearch();
        }}
        paginationLabel={t("User activity per page")}
        itemsLabel={t("user activities")}/>;

    return <PageTemplate pageTitle={t("User activity log")}>
        <UserActivityLogFilters userId={state.userId}
                                operationCode={state.operationCode}
                                onOperationChange={newOperationId => setState(state => ({
                                    ...state,
                                    operationCode: newOperationId ?? ""
                                }))}
                                onUserIdChange={newUserId => setState(state => ({...state, userId: newUserId ?? ""}))}
                                isUsersError={isUsersError}
                                isUsersLoading={isUsersLoading}
                                users={usersData?.payload?.users ?? []}
                                operations={operations}/>

        {userActivityLogError && <ErrorMessage
            message={t("Error while loading user activity log. Please try again or contact the administrator")}/>}

        {userActivityLogLoading &&
            <TableSkeleton sx={{marginTop: '40px'}}
                           colCount={4}
                           rowCount={10}
                           showPagination/>}

        {Boolean(!searchResultUserActivity?.length) && <Box sx={{mt: 2}}>
            <WarningMessage message={t("User activities not found")}/>
        </Box>}

        {Boolean(searchResultUserActivity?.length) && <Fragment>
            <UserActivityLogSearchInfoGrid/>

            <UserActivityLogTable users={userActivityLogData?.payload?.users ?? []}
                                  operations={operations}
                                  userActivities={searchResultUserActivity}
                                  onCopyClick={() => setState(state => ({
                                      ...state,
                                      openCopiedToClipboardSnackbar: true
                                  }))}/>

            <UserActivityLogSearchInfoGrid/>
        </Fragment>}

        <Snackbar open={state.openCopiedToClipboardSnackbar} autoHideDuration={4000}
                  onClose={handleCloseCopiedToClipboardSnackbar}>
            <Alert onClose={handleCloseCopiedToClipboardSnackbar} severity="success">
                {t("Log data copied to clipboard")}
            </Alert>
        </Snackbar>
    </PageTemplate>
}

interface UserActivityLogState {
    currentPage: number;
    pageSize: number;
    userId: string;
    openCopiedToClipboardSnackbar: boolean;
    operationCode: string;
}