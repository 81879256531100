import {FunctionComponent} from "react";
import {CommonLicensePropEditorProps} from "./CommonLicensePropEditorProps";
import {SToneProgrammabilityPropEditor} from "./SToneProgrammabilityPropEditor";
import {LicensePropKeys} from "../book/LicensePropKeys";

export const LicensePropEditorProxy: FunctionComponent<LicensePropEditorProxyProps> = props => {
    switch (props.propKey) {
        case LicensePropKeys.SToneProgrammability:
            return <SToneProgrammabilityPropEditor propValue={props.propValue}
                                                   onChange={props.onChange}
                                                   onErrorChange={props.onErrorChange}/>
        // case LicensePropKeys.FreeText:
        //     return <FreeTextPropEditor propValue={props.propValue}
        //                                onChange={props.onChange}
        //                                onErrorChange={props.onErrorChange}/>
        default:
            return null;
    }
}

export interface LicensePropEditorProxyProps extends CommonLicensePropEditorProps {
    propKey: string;
}