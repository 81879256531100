import {AppRoute} from '../../../book/AppRoute';
import React, {FunctionComponent, useEffect, useState} from "react";
import {UserUtils} from "../../../book/UserUtils";
import {AppState, useAppDispatch, useAppSelector} from '../../../store/AppStore';
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {usePostApiV1LoginMutation} from "../../../api/CloudLicensesManagerApi";
import {Loader} from "../../common/loader/Loader";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {userLoginAction} from "../../../action/UserLoginAction";

export const LoginPaper = styled(Paper)(({theme}) => ({
    margin: "0px auto", width: "90%",
    maxWidth: "450px",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: "80px",
    textAlign: 'center',
    overflow: 'hidden'
}));

export const Login: FunctionComponent = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const tokenExpirationDate = useAppSelector((appState: AppState) => (appState.userState.tokenExpirationDate));
    const [state, setState] = useState<LoginState>({
        username: '',
        password: '',
        showPassword: false
    });
    const [login, {data: loginResult, isLoading: isLogIn, isError: isLoginError}] = usePostApiV1LoginMutation();
    const dispatch = useAppDispatch();
    const token = loginResult?.payload?.token;
    const expiredAt = loginResult?.payload?.expiredAt;

    useEffect(() => {
        if (UserUtils.isLoggedIn(tokenExpirationDate)) {
            navigate(AppRoute.Home);
        }
    }, [tokenExpirationDate]);

    useEffect(() => {
        if (token && expiredAt) {
            dispatch(userLoginAction(token, expiredAt));
        }
    }, [token, expiredAt]);

    const handleClickShowPassword = () => {
        setState(state => ({...state, showPassword: !state.showPassword}));
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return <LoginPaper elevation={6}>
        {isLogIn && <Loader message="Login…"/>}

        {!isLogIn && <React.Fragment>
            <div>
                <img src="carel-login.png" alt="Cloud Licenses Manager"/>
            </div>

            <Typography variant="h4" sx={{marginTop: '35px'}}>
                Cloud Licenses Manager
            </Typography>
            <Typography variant="h5">
                Login
            </Typography>

            <form style={{overflow: 'hidden'}}
                  onSubmit={(e) => {
                      login({loginRequest: {username: state.username, password: state.password}});
                      e.preventDefault();
                  }}>
                <TextField
                    required
                    value={state.username}
                    onChange={e => setState(state => ({...state, username: e.target.value}))}
                    label="Username"
                    fullWidth
                    margin="normal"/>

                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        fullWidth
                        type={state.showPassword ? 'text' : 'password'}
                        onChange={e => setState(state => ({...state, password: e.target.value}))}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end">
                                    {state.showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>

                <Button
                    disabled={!state.username || !state.password}
                    variant="contained"
                    type="submit"
                    sx={{
                        marginTop: "1em",
                        marginBottom: "1em",
                        float: "right"
                    }}
                    color="primary">
                    Login
                </Button>
            </form>

            {(Boolean(loginResult?.error) || isLoginError) &&
                <Box color="error.main">
                    {t("Error while login, please check username and password and retry")}
                </Box>}
        </React.Fragment>}
    </LoginPaper>;
}

interface LoginState {
    username: string;
    password: string;
    showPassword: boolean;
}
