import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {PageTemplate} from "../../common/page-template/PageTemplate";
import {
    Box,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import {SearchInfo} from "../../common/search-info-grid/SearchInfo";
import {AppRoute} from "../../../book/AppRoute";
import {Link} from "react-router-dom";
import {TableSkeleton} from "../../common/skeleton/TableSkeleton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import {OwnerDepartmentUtils} from "../../../book/OwnerDepartmentUtils";
import {DateUtils} from "../../../book/DateUtils";
import {ResponsiveTableCell} from "../../common/responsive-table-cell/ResponsiveTableCell";
import {ErrorMessage} from "../../common/error-message/ErrorMessage";
import {useOrderListProps} from "./UseOrderListProps";
import {WarningMessage} from "../../common/error-message/WarningMessage";
import {Constants} from "../../../book/Constants";

export const OrderList: FunctionComponent = props => {
    const {t} = useTranslation();
    const {
        isOrdersLoading,
        pageSize,
        currentPage,
        isOrdersError,
        searchResultProducts,
        callOrdersSearch,
        searchResultCount,
        search
    } = useOrderListProps();

    const OrdersSearchInfoGrid = () => <SearchInfo
        count={searchResultCount}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={currentPage => {
            callOrdersSearch(currentPage, pageSize, search);
        }}
        onPageSizeChange={pageSize => {
            callOrdersSearch(currentPage, pageSize, search);
        }}
        paginationLabel={t("Orders per page")}
        itemsLabel={t("orders")}/>;

    return <PageTemplate pageTitle={t("Order list")}>
        <FormControl fullWidth variant="outlined">
            <InputLabel
                htmlFor="search-products">{t("Search by order number, serial number, customer, or department…")}</InputLabel>
            <OutlinedInput value={search}
                           fullWidth
                           label={t("Search by order number, serial number, customer, or department…")}
                           sx={{marginBottom: '20px'}}
                           onChange={e => {
                               callOrdersSearch(currentPage, pageSize, e.target.value);
                           }}
                           endAdornment={<InputAdornment position="end"><SearchIcon/></InputAdornment>}/>
        </FormControl>

        {isOrdersError && <ErrorMessage
            message={t("Error while loading orders. Please try again or contact the administrator")}/>}

        {isOrdersLoading &&
            <TableSkeleton sx={{marginTop: '40px'}}
                           colCount={9}
                           colCountMd={8}
                           colCountSm={7}
                           colCountXs={6}
                           rowCount={10}
                           showPagination/>}

        {Boolean(searchResultProducts.length) && <React.Fragment>
            <OrdersSearchInfoGrid/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell rowSpan={2}>{t("Order number")}</TableCell>
                        <TableCell rowSpan={2}>{t("Customer")}</TableCell>
                        <TableCell colSpan={3} sx={{textAlign: 'center'}}>
                            {t("Serial numbers")}
                        </TableCell>
                        <ResponsiveTableCell hideDown="md" rowSpan={2}>{t("Reference email")}</ResponsiveTableCell>
                        <ResponsiveTableCell hideDown="sm" rowSpan={2}>{t("Owner dept.")}</ResponsiveTableCell>
                        <ResponsiveTableCell hideDown="lg" rowSpan={2}>{t("Ref. date")}</ResponsiveTableCell>
                        <TableCell rowSpan={2}/>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{textAlign: 'center'}}>{t("Total")}</TableCell>
                        <TableCell sx={{textAlign: 'center'}}>{t("Used")}</TableCell>
                        <TableCell sx={{textAlign: 'center'}}>{t("Free")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {searchResultProducts.map(order => <TableRow key={`order-table-row-${order.orderId}`}>
                        <TableCell>
                            {order.orderNumber}
                            {order.orderId===Constants.stoneFakeActivationOrderId &&
                                <Box>(internal order)</Box>}
                        </TableCell>
                        <TableCell>{order.customerId} - {order.customerCode}</TableCell>
                        <TableCell>{order.serialCount}</TableCell>
                        <TableCell>{order.usedSerialCount}</TableCell>
                        <TableCell>
                            {(order.serialCount) - (order.usedSerialCount)}
                        </TableCell>
                        <ResponsiveTableCell hideDown="md">
                            <a href={`mailto:${order.referenceEmail}`}
                               title={t("sendEmail", {email: order.referenceEmail})}>
                                {order.referenceEmail}
                            </a>
                        </ResponsiveTableCell>
                        <ResponsiveTableCell hideDown="sm">
                            {OwnerDepartmentUtils.getName(order.carelBranch)}
                        </ResponsiveTableCell>
                        <ResponsiveTableCell hideDown="lg">
                            {DateUtils.dateTimeFormat(order.referenceDate)}
                        </ResponsiveTableCell>
                        <TableCell>
                            <IconButton
                                title={t("orderDetail", {orderNumber: order.orderNumber})}
                                size="small"
                                aria-label={t("orderDetail", {orderNumber: order.orderNumber})}
                                component={Link}
                                to={`${AppRoute.Order.replace(':orderId', order.orderId)}`}>
                                <NavigateNextIcon/>
                            </IconButton>
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>

            <OrdersSearchInfoGrid/>

        </React.Fragment>}

        {!Boolean(searchResultProducts.length) && !isOrdersError && !isOrdersLoading && <WarningMessage message="Orders not found" />}
    </PageTemplate>;
}