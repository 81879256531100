import * as React from "react";
import {FunctionComponent} from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {drawerWidth} from "./DrawerHeader";
import {AppRoute} from "../../../book/AppRoute";
import {useLocation, useNavigate} from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import {Box, Divider, Drawer} from "@mui/material";
import {UserRole} from "../../../book/UserRoleUtils";
import GroupIcon from '@mui/icons-material/Group';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ViewListIcon from '@mui/icons-material/ViewList';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {useTranslation} from "react-i18next";
import Inventory2Icon from '@mui/icons-material/Inventory2';

export const AppDrawer: FunctionComponent<AppDrawerProps> = props => {
    const {window} = props;
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();

    const menu = [
        {
            name: t('Order list'),
            icon: <ShoppingCartIcon/>,
            link: AppRoute.OrderList,
            role: UserRole.User
        },
        {
            name: t('Activation list'),
            icon: <DeviceHubIcon/>,
            link: AppRoute.ActivationList,
            role: UserRole.User
        },
        null,
        {
            name: t('Users management'),
            icon: <GroupIcon/>,
            link: AppRoute.UsersManagement,
            role: UserRole.Admin
        },
        {
            name: t('Products'),
            icon: <Inventory2Icon/>,
            link: AppRoute.Products,
            role: UserRole.Admin
        },
        {
            name: t('User activity log'),
            icon: <ViewListIcon/>,
            link: AppRoute.UserActivityLog,
            role: UserRole.Admin
        },
        null,
        {
            name: t('Credits'),
            icon: <FormatListBulletedIcon/>,
            link: AppRoute.Credits,
            role: UserRole.User
        }
    ];

    const container = window !== undefined ? () => window().document.body : undefined;

    const drawer = (
        <div>
            <Toolbar/>
            <Divider/>

            <List>
                {menu.map((menuItem, index) => {
                    if (!menuItem) {
                        return <Divider key={`divider-${index}`}/>;
                    }

                    return props.userRole >= menuItem.role &&
                        <ListItem disablePadding
                                  sx={{
                                      display: 'block',
                                      '& .Mui-selected': {
                                          backgroundColor: 'rgba(0, 0, 0, 0.08)'
                                      }
                                  }}
                                  key={menuItem.name}>
                            <ListItemButton onClick={() => navigate(menuItem.link)}
                                            selected={menuItem.link === location.pathname}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: 'initial',
                                                px: 2.5
                                            }}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 3,
                                        justifyContent: 'center',
                                    }}>
                                    {menuItem.icon}
                                </ListItemIcon>
                                <ListItemText primary={menuItem.name}/>
                            </ListItemButton>
                        </ListItem>;
                })}
            </List>
        </div>
    );

    return <Box component="nav" sx={{width: {md: drawerWidth}, flexShrink: {md: 0}}}>
        <Drawer container={container}
                variant="temporary"
                open={props.isMobileOpen}
                onClose={props.handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: 'block', md: 'none'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                }}>
            {drawer}
        </Drawer>
        <Drawer
            variant="permanent"
            sx={{
                display: {xs: 'none', md: 'block'},
                '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
            }}
            open>
            {drawer}
        </Drawer>
    </Box>;
}

export interface AppDrawerProps {
    userRole: number;
    window?: () => Window;
    handleDrawerToggle: () => void;
    isMobileOpen: boolean;
}