import React, {FunctionComponent, memo} from 'react';
import {useTranslation} from "react-i18next";
import {Box, MenuItem, Pagination, Select, Theme} from "@mui/material";
import {PageSizeSelector} from "./PageSizeSelector";
import {SxProps} from "@mui/system";
import Typography from "@mui/material/Typography";

const InternalCPagination: FunctionComponent<AppPaginationProps> = props => {
    const {t} = useTranslation();

    return <Box sx={props.sx}>
        <PageSizeSelector>
            <Select role="page-select"
                    data-testid="page-select"
                    variant="standard"
                    value={props.pageSize}
                    onChange={e => props.onPageSizeChange(e.target.value as number)}>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
            </Select>
            <Typography sx={{display: 'inline-block', marginLeft: '1em'}}
                        role="item-per-page-label">
                {props.label ?? t('Items per page')}
            </Typography>
        </PageSizeSelector>

        <Box sx={{display: 'inline-block'}}>
            <Pagination role="pagination"
                        onChange={(_, value) => props.onPageChange(value - 1)}
                        defaultPage={props.defaultPage}
                        count={props.pageCount}
                        color="primary"/>
        </Box>
    </Box>;
}

export const AppPagination = memo(InternalCPagination, (prev, next) => {
    return !!prev &&
        prev.pageCount === next.pageCount &&
        prev.defaultPage === next.defaultPage &&
        prev.pageSize === next.pageSize &&
        prev.label === next.label;
});

export interface AppPaginationProps {
    onPageChange: (page: number) => void;
    pageCount: number;
    defaultPage: number;
    pageSize: number;
    onPageSizeChange: (page: number) => void;
    label?: string;
    sx?: SxProps<Theme>
}