import {styled} from "@mui/material/styles";
import {Breakpoint, TableCell} from "@mui/material";
import {TableCellProps} from "@mui/material/TableCell/TableCell";

export const ResponsiveTableCell = styled(TableCell)<ResponsiveTableCellProps>(props => {
    let style = {};

    if (props.hideDown) {
        style = {
            ...style,
            [props.theme.breakpoints.down(props.hideDown)]: {
                display: 'none'
            }
        }
    }

    if (props.hideUp) {
        style = {
            ...style,
            [props.theme.breakpoints.up(props.hideUp)]: {
                display: 'none'
            }
        }
    }

    return style;
})

export interface ResponsiveTableCellProps extends TableCellProps {
    hideDown?: Breakpoint | number;
    hideUp?: Breakpoint | number;
}