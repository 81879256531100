import {FunctionComponent} from "react";
import {alpha, Box, SxProps, Theme} from "@mui/material";
import {styled} from "@mui/material/styles";

const Sticker = styled(Box)(() => ({
    position: 'fixed',
    zIndex: 5000,
    left: 'calc(50% - 75px)',
    right: 'calc(50% - 75px)',
    top: 0,
    padding: 4,
    width: 150,
    textAlign: 'center',
    borderBottomRightRadius: '6px',
    borderBottomLeftRadius: '6px',
    // Text
    fontWeight: 500,
    color: 'white',
    fontSize: '.95em',
    // Glass effect
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(8px)'
}));

export const EnvironmentSticker: FunctionComponent = () => {
    const url = window.location.href;
    const environment: [string | undefined, SxProps<Theme>] = ((() => {
        if (url.indexOf("-qa") !== -1) {
            return ["QA", {
                backgroundColor: alpha('#9F2B68', 0.2)
            }];
        }

        if (url.indexOf("windows.net") !== -1) {
            return ["DEV", {
                backgroundColor: alpha('#7FFF00', 0.2)
            }];
        }

        if (url.indexOf("localhost") !== -1 || url.indexOf("127.0.0.1") !== -1) {
            return ["LOCAL", {
                backgroundColor: alpha('#ffa500', 0.2)
            }];
        }

        return [undefined, {}];
    })());

    if (environment[0]) {
        return <Sticker sx={{...environment[1]}}>{environment[0]}</Sticker>;
    }

    return null;
}