import {Fragment, FunctionComponent} from "react";
import {TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

export const NotesEditor: FunctionComponent<NotesEditorProp> = props => {
    const { t } = useTranslation();

    return <Fragment>
        <Typography variant="h6" sx={{marginBottom: '0.8em', marginTop: '0.8em'}}>
            {t("Notes")}
        </Typography>

        <TextField sx={{ marginBottom: '2em' }}
            label={t("Notes")}
            maxRows={5}
            minRows={1}
            inputProps={{ maxLength: 65535 }}
            multiline
            value={props.value}
            fullWidth
            onChange={(ev) => {
                props.setValue(ev.target.value);
            }} />
    </Fragment>
}

export interface NotesEditorProp {
    value: string;
    setValue: (newValue: string) => void;
}