import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";

export const Footer = styled(Box)(() => ({
    position: "fixed",
    bottom: "0",
    left: "0",
    right: "0",
    height: "75px",
    zIndex: 1201,
    backgroundColor: "black",
    color: 'white',
    textAlign: 'center',
    fontSize: '0.9em',
    '> a': {
        width: "75px",
        height: "50px",
        display: "block",
        margin: "0 auto",
        backgroundImage: 'url("carel-logo.png")',
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "75px 40px"
    }
}))