import React, {Fragment, useState} from "react";
import {Button, ButtonGroup, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {ModeEdit} from "@mui/icons-material";

export const HardwareIdEditor: React.FunctionComponent<HardwareIdEditorProp> = (props) => {
    const {t} = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const [editedValue, setEditedValue] = useState(props.value);

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
        if (!isEditing) {
            setEditedValue(props.value);
        }
    };

    const handleOk = () => {
        props.setValue(editedValue);
        setIsEditing(false);
    };

    return (
        <Fragment>
            <Typography variant="h6" sx={{marginBottom: '0.8em', marginTop: '0.8em'}}>
                {t("Hardware ID")}
            </Typography>

            {isEditing ? (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <TextField
                        label={t("Hardware ID")}
                        InputProps={{
                            inputProps: {maxLength: 65535}
                        }}
                        maxRows={1}
                        style={{width: '400px'}}
                        multiline
                        value={editedValue}
                        fullWidth
                        onChange={(ev) => {
                            setEditedValue(ev.target.value);
                        }}/>
                    <ButtonGroup sx={{marginLeft: '0.8em'}}>
                        <Button variant="outlined" onClick={handleOk}>
                            {t("Ok")}
                        </Button>
                        <Button variant="outlined" onClick={handleEditToggle}>
                            {t("Cancel")}
                        </Button>
                    </ButtonGroup>
                </div>
            ) : (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <TextField
                        label={t("Hardware ID")}
                        contentEditable={false}
                        maxRows={1}
                        InputProps={{
                            readOnly: true,
                            inputProps: {maxLength: 65535}
                        }}
                        style={{width: '400px'}}
                        value={props.value}>
                    </TextField>
                    <IconButton aria-label="copy hardware id"
                                title={t("Copy hardware ID")}
                                onClick={async () => await navigator.clipboard.writeText(props.value)}>
                        <ContentCopyIcon/>
                    </IconButton>
                    <IconButton title={t("Edit hardware ID")} onClick={handleEditToggle}>
                        <ModeEdit/>
                    </IconButton>
                </div>
            )}
        </Fragment>
    );
};

export interface HardwareIdEditorProp {
    value: string;
    setValue: (newValue: string) => void;
}