import React, {FunctionComponent} from "react";
import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import {useTranslation} from "react-i18next";

export interface ConfirmDeleteSerialNumberDialogProps {
    onCancelClick: () => void;
    onOkClick: () => void;
    serialNumberId: string;
}

export const ConfirmDeleteSerialNumberDialog: FunctionComponent<ConfirmDeleteSerialNumberDialogProps> = props => {
    const {t} = useTranslation();

    return <Dialog maxWidth="sm"
                   aria-labelledby="confirmation-dialog-title"
                   open={true}
                   onClose={() => props.onCancelClick()}>
        <DialogTitle id="confirmation-dialog-title">{t("Delete serial number")}</DialogTitle>
        <DialogContent dividers>
            <DialogContentText>
                Are you sure to delete serial number <strong>{props.serialNumberId}</strong>?
                <Typography variant="body2" gutterBottom>
                    {t("The user will no longer be allowed to use the serial number in the application.")}
                </Typography>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => props.onOkClick()} color="primary">
                {t("Ok")}
            </Button>
            <Button autoFocus onClick={() => props.onCancelClick()} color="primary">
                {t("Cancel")}
            </Button>
        </DialogActions>
    </Dialog>;
}