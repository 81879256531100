import {useTranslation} from "react-i18next";
import React, {FunctionComponent, useEffect, useState} from "react";
import {PageTemplate} from "../../common/page-template/PageTemplate";
import {FormControl, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import {
    ActivationItem,
    usePostApiV1ActivationListMutation,
    usePostApiV1FreeActivationMutation
} from "../../../api/CloudLicensesManagerApi";
import {TableSkeleton} from "../../common/skeleton/TableSkeleton";
import {SearchInfo} from "../../common/search-info-grid/SearchInfo";
import {
    ProcessStatusNotification,
    toProcessStatus
} from "../../common/process-status-notification/ProcessStatusNotification";
import {FreeActivationDialog} from "../../common/free-activation-dialog/FreeActivationDialog";
import {ActivationsTable} from "../../common/activation-table/ActivationsTable";
import SearchIcon from "@mui/icons-material/Search";
import {ErrorMessage} from "../../common/error-message/ErrorMessage";

export const ActivationList: FunctionComponent = props => {
    const {t} = useTranslation();
    const [state, setState] = useState<ActivationListState>({
        currentPage: 0,
        pageSize: 50,
        search: ''
    });
    const [activationsSearch, {
        data: activationsSearchData,
        isLoading: isActivationsLoading,
        isError: isActivationsError
    }] = usePostApiV1ActivationListMutation();
    const [freeActivation, {
        data: freeActivationData,
        isLoading: isFreeActivationLoading,
        error: freeActivationError,
        reset: resetFreeActivation
    }] = usePostApiV1FreeActivationMutation();

    const searchResultActivations = activationsSearchData?.payload?.activations ?? [];
    const searchResultCount = activationsSearchData?.payload?.count ?? 0;

    const callActivationSearch = () => {
        activationsSearch({
            activationListRequest: {
                search: state.search.length ? state.search : null,
                page: state.currentPage,
                pageSize: state.pageSize
            }
        });
    }

    useEffect(() => {
        callActivationSearch();
    }, [state.search, state.currentPage, state.pageSize]);

    const ActivationsSearchInfoGrid = () => <SearchInfo
        count={searchResultCount}
        currentPage={state.currentPage}
        pageSize={state.pageSize}
        onPageChange={page => {
            setState(state => ({...state, currentPage: page}));
            callActivationSearch();
        }}
        onPageSizeChange={pageSize => {
            setState(state => ({...state, pageSize}));
            callActivationSearch();
        }}
        paginationLabel={t("Orders per page")}
        itemsLabel={t("orders")}/>;

    return <PageTemplate pageTitle={t("Activation list")}>
        <FormControl fullWidth variant="outlined">
            <InputLabel
                htmlFor="search-products">{t("Search activations by hardware ID or serial number…")}</InputLabel>
            <OutlinedInput value={state.search}
                           fullWidth
                           label={t("Search activations by hardware ID or serial number…")}
                           sx={{marginBottom: '20px'}}
                           onChange={e => {
                               setState(state => ({...state, search: e.target.value}))
                           }}
                           endAdornment={<InputAdornment position="end"><SearchIcon/></InputAdornment>}/>
        </FormControl>

        {isActivationsError && <ErrorMessage
            message={t("Error while loading activations. Please try again or contact the administrator")}/>}

        {isActivationsLoading &&
            <TableSkeleton sx={{marginTop: '40px'}}
                           colCount={5}
                           colCountMd={4}
                           rowCount={5}
                           showPagination/>}

        {Boolean(searchResultActivations.length) &&
            <React.Fragment>
                <ActivationsSearchInfoGrid/>

                <ActivationsTable activations={searchResultActivations}
                                  onFreeActivationClick={(activation) => {
                                      setState(state => ({...state, activationToFree: activation}));
                                  }}/>

                <ActivationsSearchInfoGrid/>

            </React.Fragment>
        }

        <ProcessStatusNotification
            status={toProcessStatus(isFreeActivationLoading, Boolean(freeActivationData?.payload), Boolean(freeActivationError))}
            onClose={() => resetFreeActivation()}
            onLoadingMessage={t("Freeing activation please wait…")}
            onSuccessMessage={t("Activation freed successfully")}
            onErrorMessage={t("Activation freeing failed")}/>

        <FreeActivationDialog open={Boolean(state.activationToFree)}
                              activation={state.activationToFree}
                              onCancelClick={() => {
                                  setState(state => ({
                                      ...state,
                                      activationToFree: undefined
                                  }));
                              }}
                              onOkClick={() => {
                                  freeActivation({
                                      freeActivationRequest: {
                                          activationId: state.activationToFree?.activationId!
                                      }
                                  })
                              }}/>
    </PageTemplate>;
}

export interface ActivationListState {
    currentPage: number;
    pageSize: number;
    search: string;
    activationToFree?: ActivationItem;
}