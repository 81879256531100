import {FunctionComponent} from "react";

export interface Flag extends FunctionComponent<FlagProps> { }

export const defaultFlagProps: FlagProps = {
    width: 150,
    height: 100
};

export interface FlagProps {
    width?: number;
    height?: number;
    className?: string;
}