import React, {Fragment, FunctionComponent} from "react";
import Typography from "@mui/material/Typography";
import {TextField} from "@mui/material";
import {useTranslation} from "react-i18next";

export const SignatureEditor: FunctionComponent<SignatureEditorProp> = props => {
    const {t} = useTranslation();

    return <Fragment>
        <Typography variant="h6" sx={{marginBottom: '0.8em'}}>
            {t("Signatures")}
        </Typography>

        <TextField label={t("Signatures")}
                   value={props.values.join(";")}
                   fullWidth
                   onChange={(ev) => {
                       props.setValue(ev.target.value.split(";"));
                   }}
                   helperText={t('* use semicolon (";") as separator')}/>
    </Fragment>
}

export interface SignatureEditorProp {
    values: string[];
    setValue: (newValues: string[]) => void;
}