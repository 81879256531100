import {useEffect, useState} from "react";
import {usePostApiV1OrderListMutation} from "../../../api/CloudLicensesManagerApi";

export const useOrderListProps = () => {
    const [state, setState] = useState<ProductSearchState>({
        currentPage: 0,
        pageSize: 50,
        search: ''
    });
    const [ordersSearch, {
        data: ordersSearchData,
        isLoading: isOrdersLoading,
        isError: isOrdersError
    }] = usePostApiV1OrderListMutation();

    const searchResultProducts = ordersSearchData?.payload?.orders ?? [];
    const searchResultCount = ordersSearchData?.payload?.count ?? 0;

    const callOrdersSearch = () => {
        ordersSearch({
            searchOrdersRequest: {
                search: state.search.length ? state.search : null,
                page: state.currentPage,
                pageSize: state.pageSize
            }
        });
    }

    useEffect(() => {
        callOrdersSearch();
    }, [state.search, state.currentPage, state.pageSize]);

    return {
        searchResultCount,
        currentPage: state.currentPage,
        pageSize: state.pageSize,
        callOrdersSearch: (currentPage: number, pageSize: number, search: string) => {
            setState({
                search,
                currentPage,
                pageSize
            });
        },
        isOrdersError,
        isOrdersLoading,
        searchResultProducts,
        search: state.search
    }
}

interface ProductSearchState {
    currentPage: number;
    pageSize: number;
    search: string;
}