import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {EnFlag} from "./EnFlag";
import {ItFlag} from "./ItFlag";
import {Box, Menu, MenuItem} from "@mui/material";
import {LanguageCodes} from "../../../book/LanguageCodes";

const FlagImage: FunctionComponent<FlagImageProps> = props => {
    const width = 37;
    const height = 25;
    const classname = 'lang-flag';

    switch (props.language) {
        case LanguageCodes.EN:
            return <EnFlag height={height} width={width} />;
        case LanguageCodes.IT:
            return <ItFlag height={height} width={width} />;
        default:
            return null;
    }
};

interface FlagImageProps {
    language: string;
}

export const LanguageMenu: FunctionComponent<LanguageMenuProps> = props => {
    const {i18n} = useTranslation()
    const currentLanguage = i18n.language;
    const supportedLanguages = ['it', 'en'];

    return <Menu
        anchorEl={props.anchorEl}
        open={props.isOpen}
        keepMounted
        onClose={() => props.handleClose()}>
        {supportedLanguages.map(language => <MenuItem key={language} onClick={() => {
            props.handleClose(language);
        }} selected={language === currentLanguage.substring(0, 2)}>
            <FlagImage language={language}/>
            <Box component="span" sx={{paddingLeft: '10px'}}>{language.toUpperCase()}</Box>
        </MenuItem>)}
    </Menu>;
}

export interface LanguageMenuProps {
    isOpen: boolean;
    anchorEl: HTMLElement | null;
    handleClose: (languageCode?: string) => void;
}