import React, {FunctionComponent, useEffect} from "react";
import {CommonLicensePropEditorProps} from "./CommonLicensePropEditorProps";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import {JsonUtils} from "../../../../book/JsonUtils";
import InfoIcon from '@mui/icons-material/Info';
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";

export const SToneProgrammabilityPropEditor: FunctionComponent<CommonLicensePropEditorProps> = props => {
    const programmability: SToneProgrammabilityPropEditorState = props.propValue && props.propValue !== "" && JsonUtils.isValid(props.propValue) ? JSON.parse(props.propValue) : {};
    const [openInfo, setOpenInfo] = React.useState(false);

    const handleClose = () => {
        setOpenInfo(false);
    };

    // Errors
    useEffect(() => {
        const familyIdError = (!programmability.familyId);
        const modelIdError = (!programmability.modelId) && (Boolean(programmability.core) || Boolean(programmability.stoneVersion));
        const coreError = false; // (!state.core || state.core === '') && Boolean(state.stoneVersion);

        props.onErrorChange(modelIdError || familyIdError || coreError);
    }, [programmability.modelId, programmability.familyId, programmability.core, programmability.stoneVersion]);

    return <Stack direction='row' spacing={1} sx={{alignItems: 'center'}}>
        <TextField label="Family ID"
                   value={programmability.familyId ? parseInt('' + programmability.familyId) : programmability.familyId}
                   variant="outlined"
                   error={(!programmability.familyId)}
                   sx={{width: '95px'}}
                   onChange={e => {
                       const value = e.target.value;
                       props.onChange(JSON.stringify({
                           ...programmability,
                           familyId: value && value !== '' ? parseInt(value) : null
                       }));
                   }}/>
        <TextField label="Model ID"
                   value={programmability.modelId ? parseInt('' + programmability.modelId) : programmability.modelId}
                   aria-label="model id"
                   className={`stone-programmability-model-id`}
                   variant="outlined"
                   error={(!programmability.modelId) && (Boolean(programmability.core) || Boolean(programmability.stoneVersion))}
                   sx={{width: '95px'}}
                   onChange={e => {
                       const value = e.target.value;
                       props.onChange(JSON.stringify({
                           ...programmability,
                           modelId: value && value !== '' ? parseInt(value) : null
                       }));
                   }}/>
        <TextField label="Core"
                   value={programmability.core ? parseInt('' + programmability.core) : programmability.core}
                   variant="outlined"
                   sx={{width: '95px'}}
                   onChange={e => {
                       const value = e.target.value;
                       props.onChange(JSON.stringify({
                           ...programmability,
                           core: value && value !== '' ? parseInt(value) : null
                       }));
                   }}/>
        <TextField label="STone version"
                   value={programmability.stoneVersion}
                   variant="outlined"
                   sx={{width: '95px'}}
                   onChange={e => {
                       props.onChange(JSON.stringify({...programmability, stoneVersion: e.target.value}));
                   }}/>

        <IconButton title={"Show programmability info"} onClick={() => setOpenInfo(true)}>
            <InfoIcon/>
        </IconButton>

        <Dialog onClose={handleClose} open={openInfo} maxWidth="xl">
            <DialogTitle>STone programmability</DialogTitle>
            <DialogContent>
                <Typography>This prop enables the programmability on Carel hardware using STone IDE.</Typography>
                <Typography>You can set:
                    <ul>
                        <li>only the family ID</li>
                        <li>the family ID and the model</li>
                        <li>the family ID, the model and the core</li>
                    </ul>
                </Typography>
                <Typography>The following table list the family ID, the model ID and the core of Carel hardware</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="s0"></TableCell>
                            <TableCell className="s1" dir="ltr">Family ID</TableCell>
                            <TableCell className="s1" dir="ltr">Model ID</TableCell>
                            <TableCell className="s1" dir="ltr">Core</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableRow>
                        <TableCell className="s2">aCU</TableCell>
                        <TableCell className="s3">6</TableCell>
                        <TableCell className="s3">51</TableCell>
                        <TableCell className="s3">0</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">aCU High End</TableCell>
                        <TableCell className="s3">6</TableCell>
                        <TableCell className="s3">52</TableCell>
                        <TableCell className="s3">1</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">bCU</TableCell>
                        <TableCell className="s3">7</TableCell>
                        <TableCell className="s3">53</TableCell>
                        <TableCell className="s3">0</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">bCU</TableCell>
                        <TableCell className="s3">7</TableCell>
                        <TableCell className="s3">53</TableCell>
                        <TableCell className="s3">1</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">c.pCO</TableCell>
                        <TableCell className="s3">8</TableCell>
                        <TableCell className="s3">12</TableCell>
                        <TableCell className="s3">2</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">c.pCO OEM</TableCell>
                        <TableCell className="s3">8</TableCell>
                        <TableCell className="s3">13</TableCell>
                        <TableCell className="s3">2</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">c.pCO-mini</TableCell>
                        <TableCell className="s3">8</TableCell>
                        <TableCell className="s3">14</TableCell>
                        <TableCell className="s3">2</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">c.pCO-mini</TableCell>
                        <TableCell className="s3">8</TableCell>
                        <TableCell className="s3">14</TableCell>
                        <TableCell className="s3">5</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">uPC3</TableCell>
                        <TableCell className="s3">8</TableCell>
                        <TableCell className="s3">17</TableCell>
                        <TableCell className="s3">2</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">uPC3</TableCell>
                        <TableCell className="s3">8</TableCell>
                        <TableCell className="s3">17</TableCell>
                        <TableCell className="s3">4</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">HEOS</TableCell>
                        <TableCell className="s3">8</TableCell>
                        <TableCell className="s3">20</TableCell>
                        <TableCell className="s3">2</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">pCO5+HS</TableCell>
                        <TableCell className="s3">9</TableCell>
                        <TableCell className="s3">12</TableCell>
                        <TableCell className="s3">2</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">pC-OEM+HS</TableCell>
                        <TableCell className="s3">9</TableCell>
                        <TableCell className="s3">13</TableCell>
                        <TableCell className="s3">2</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">uPC3 single chip</TableCell>
                        <TableCell className="s3">10</TableCell>
                        <TableCell className="s3">17</TableCell>
                        <TableCell className="s3">2</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">uPC3 single chip</TableCell>
                        <TableCell className="s3">10</TableCell>
                        <TableCell className="s3">17</TableCell>
                        <TableCell className="s3">4</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">CHCP</TableCell>
                        <TableCell className="s3">8</TableCell>
                        <TableCell className="s3">21</TableCell>
                        <TableCell className="s3">5</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">MPXpro</TableCell>
                        <TableCell className="s3">11</TableCell>
                        <TableCell className="s3">22</TableCell>
                        <TableCell className="s3">5</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="s2">bCU-IoT (YinDu)</TableCell>
                        <TableCell className="s3">12</TableCell>
                        <TableCell className="s3">54</TableCell>
                        <TableCell className="s3">0</TableCell>
                    </TableRow>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    </Stack>
}

interface SToneProgrammabilityPropEditorState extends SToneProgrammability {
}

export interface SToneProgrammability {
    modelId?: number;
    familyId?: number;
    core?: number;
    stoneVersion?: string;
}