import {Box, CircularProgress} from "@mui/material";
import React, {FunctionComponent} from "react";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const LoaderBox = styled(Box)(({theme}) => ({
    textAlign: "center",
    '> .message': {
        marginTop: '0.7em'
    }
}))

export const Loader: FunctionComponent<LoaderProps> = props => {
    return <LoaderBox>
        <Box className="progress">
            <CircularProgress size={props.circularProgressSize}/>
        </Box>
        <Typography variant="body2" className="message" role="message">
            {props.message}
        </Typography>
    </LoaderBox>;
}

export interface LoaderProps {
    message?: string;
    circularProgressSize?: string | number;
}