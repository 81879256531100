export class OwnerDepartmentUtils {
    public static getName(code: string | undefined | null): string {
        switch (code) {
            case "89":
                return "CAREL FRANCE SARL";
            case "90":
                return "CAREL U.K. LTD";
            case "133":
                return "CAREL USA, LLC";
            case "153":
                return "CAREL ELECTRONIC (SUZHOU) CO.LTD";
            case "191":
                return "CAREL DEUTSCHLAND GMBH";
            case "333":
                return "CAREL INDUSTRIES SPA";
            case "351":
                return "CAREL IBERICA";
            case "392":
                return "CAREL SUD AMERICA (CBX - DB)";
            case "511":
                return "CAREL ADRIATIC";
            default :
                return `Department code: ${code}`
        }
    }
}