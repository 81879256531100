export class JsonUtils {
    public static isValid = (jsonString: string): boolean => {
        try {
            JSON.parse(jsonString);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}