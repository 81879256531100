import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {ActivationItem} from "../../../api/CloudLicensesManagerApi";
import {DateUtils} from "../../../book/DateUtils";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from "@mui/material/IconButton/IconButton";

export const ActivationInfo: FunctionComponent<ActivationInfoProps> = props => {
    const {t} = useTranslation();

    return <ul style={{padding: '0 0 0 18px', margin: 0}}>
        <li>
            <b>{t("Company")}:</b> {props.activation.company}
        </li>
        <li>
            <b>Address:</b> {props.activation.address}
        </li>
        <li>
            <b>{t("Country")}:</b> {props.activation.country}
        </li>
        <li>
            <b>{t("Serial number")}:</b> {props.activation.serialNumber}
            <IconButton aria-label="copy serial number"
                        title={t("Copy serial number")}
                        onClick={async () => await navigator.clipboard.writeText(props.activation.serialNumber)}>
                <ContentCopyIcon/>
            </IconButton>
        </li>
        <li>
            <b>{t("Created")}:</b> {DateUtils.dateTimeFormat(props.activation.activationDate)}
        </li>
        <li>
            <b>{t("Last update")}:</b> {DateUtils.dateTimeFormat(props.activation.lastCheckDate)}
        </li>
    </ul>;
}

export interface ActivationInfoProps {
    activation: ActivationItem;
}