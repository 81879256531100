import React, {Fragment, FunctionComponent, useState} from "react";
import {DateUtils} from "../../../book/DateUtils";
import {OwnerDepartmentUtils} from "../../../book/OwnerDepartmentUtils";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {ButtonGroup} from "@mui/material";
import {useTranslation} from "react-i18next";
import {
    GetOrderUnusedSerialNumber,
    OrderItem,
    useDeleteApiV1SerialNumberMutation
} from "../../../api/CloudLicensesManagerApi";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {
    ProcessStatusNotification,
    toProcessStatus
} from "../../common/process-status-notification/ProcessStatusNotification";
import {ConfirmDeleteSerialNumberDialog} from "./ConfirmDeleteSerialNumberDialog";

export const OrderInfo: FunctionComponent<OrderInfoProps> = props => {
    const {t} = useTranslation();
    const [openCopySerialNumber, setOpenCopySerialNumber] = useState(false);
    const [confirmDeleteSerialNumber, setConfirmDeleteSerialNumber] = useState<{
        show: boolean,
        serialNumberId?: string | null
    }>({
        show: false,
        serialNumberId: undefined
    });
    const [deleteSerialNumber, {
        data: deleteSerialNumberData,
        isLoading: isDeleteSerialNumberLoading,
        isError: isDeleteSerialNumberError,
        reset: resetDeleteSerialNumber
    }] = useDeleteApiV1SerialNumberMutation();

    const onCancelClick = () => {
        setConfirmDeleteSerialNumber(state => ({...state, show: false}));
    }

    const onOkClick = () => {
        deleteSerialNumber({deleteSerialNumberRequest: {serialNumberId: confirmDeleteSerialNumber.serialNumberId}});
        setConfirmDeleteSerialNumber(state => ({...state, show: false}));
    }

    return <Fragment>
        <ul style={{padding: '0 0 0 18px', margin: 0}}>
            <li>
                <b>{t("Customer")}</b>: {`${props.order.customerId} - ${props.order.customerCode}`}
            </li>
            <li>
                <b>{t("Reference email")}</b>:&nbsp;
                <a href={`mailto:${props.order.referenceEmail}`}>{props.order.referenceEmail}</a>
            </li>
            <li><b>{t("Order date")}</b>:&nbsp;
                {DateUtils.dateTimeFormat(props.order.referenceDate as number)}
            </li>
            <li>
                <b>{t("Owner dept.")}</b>: {OwnerDepartmentUtils.getName(props.order.carelBranch)}
            </li>
            {Boolean(props.unusedSerialNumbers.length) && <Fragment>
                <li><b>{t("Unused serial numbers")} ({props.unusedSerialNumbers.length})</b>:</li>

                <TableContainer sx={{maxHeight: 280}}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableCell>{t("Serial number")}</TableCell>
                            <TableCell>{t("Product name")}</TableCell>
                            <TableCell>{t("Status")}</TableCell>
                            <TableCell></TableCell>
                        </TableHead>
                        <TableBody>
                            {props.unusedSerialNumbers
                                .map((row) => <TableRow hover role="checkbox" tabIndex={-1} key={row.serialNumber}>
                                    <TableCell>{row.serialNumber}</TableCell>
                                    <TableCell>{row.productName}</TableCell>
                                    <TableCell>{row.isExpired ? "Expired" : "Active"}</TableCell>
                                    <TableCell>
                                        <ButtonGroup>
                                            <IconButton title={t("Copy serial number")}
                                                        onClick={async () => {
                                                            setOpenCopySerialNumber(true);
                                                            await navigator.clipboard.writeText(row.serialNumber);
                                                        }}>
                                                <ContentCopyIcon fontSize="small"/>
                                            </IconButton>
                                            <IconButton title={t("Delete serial number")}
                                                        onClick={() => {
                                                            setConfirmDeleteSerialNumber(state => ({
                                                                ...state,
                                                                show: true,
                                                                serialNumberId: row.serialNumberId
                                                            }));
                                                        }}>
                                                <DeleteIcon fontSize="small"/>
                                            </IconButton>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Fragment>}
        </ul>

        {confirmDeleteSerialNumber.show && !!confirmDeleteSerialNumber.serialNumberId &&
            <ConfirmDeleteSerialNumberDialog serialNumberId={confirmDeleteSerialNumber.serialNumberId}
                                             onOkClick={onOkClick}
                                             onCancelClick={onCancelClick}/>}

        <Snackbar open={openCopySerialNumber}
                  autoHideDuration={3000}
                  onClose={() => setOpenCopySerialNumber(false)}>
            <Alert
                onClose={() => setOpenCopySerialNumber(false)}
                severity="success"
                role="message"
                sx={{width: '100%'}}>
                Serial number copied
            </Alert>
        </Snackbar>

        <ProcessStatusNotification
            status={toProcessStatus(isDeleteSerialNumberLoading, Boolean(deleteSerialNumberData?.payload), isDeleteSerialNumberError)}
            onClose={() => resetDeleteSerialNumber()}
            onLoadingMessage={t("Deleting serial number please wait…")}
            onSuccessMessage={t("Serial number deleted successfully")}
            onErrorMessage={t("Serial number deleting failed")}/>
    </Fragment>;
}

export interface OrderInfoProps {
    order: OrderItem;
    unusedSerialNumbers: GetOrderUnusedSerialNumber[];
    onUnusedSerialNumberClick: () => void;
}