import React, {FunctionComponent} from "react";
import {Skeleton} from "@mui/material";

export const DottedListSkeleton: FunctionComponent<DottedListSkeletonProps> = props => {
    return <ul style={{padding: '0 0 0 18px', margin: 0}}>
        {Array(props.rowCount).fill("skeleton-li").map((key, i) =>
            <li key={`${key}-${i}`} data-testId='skeleton-li'>
                <Skeleton variant="text"/>
            </li>)}
    </ul>;
};

export interface DottedListSkeletonProps {
    rowCount: number;
}