import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiV1StoneFakeActivation: build.mutation<
      PostApiV1StoneFakeActivationApiResponse,
      PostApiV1StoneFakeActivationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/stone-fake-activation`,
        method: "POST",
        body: queryArg.sToneFakeActivationRequest,
      }),
    }),
    getApiV1ActivationByActivationId: build.query<
      GetApiV1ActivationByActivationIdApiResponse,
      GetApiV1ActivationByActivationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/activation/${queryArg.activationId}`,
      }),
    }),
    postApiV1ActivationList: build.mutation<
      PostApiV1ActivationListApiResponse,
      PostApiV1ActivationListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/activation-list`,
        method: "POST",
        body: queryArg.activationListRequest,
      }),
    }),
    postApiV1AddActivationRecord: build.mutation<
      PostApiV1AddActivationRecordApiResponse,
      PostApiV1AddActivationRecordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/add-activation-record`,
        method: "POST",
        body: queryArg.addActivationRecordRequest,
      }),
    }),
    postApiV1FreeActivation: build.mutation<
      PostApiV1FreeActivationApiResponse,
      PostApiV1FreeActivationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/free-activation`,
        method: "POST",
        body: queryArg.freeActivationRequest,
      }),
    }),
    postApiV1OfflineLicenseActivation: build.mutation<
      PostApiV1OfflineLicenseActivationApiResponse,
      PostApiV1OfflineLicenseActivationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/offline-license-activation`,
        method: "POST",
        body: queryArg.offlineLicenseActivationRequest,
      }),
    }),
    postApiV1OfflinePlainLicenseActivation: build.mutation<
      PostApiV1OfflinePlainLicenseActivationApiResponse,
      PostApiV1OfflinePlainLicenseActivationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/offline-plain-license-activation`,
        method: "POST",
        body: queryArg.offlineLicenseActivationRequest,
      }),
    }),
    postApiV1ProductActivation: build.mutation<
      PostApiV1ProductActivationApiResponse,
      PostApiV1ProductActivationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/product-activation`,
        method: "POST",
        body: queryArg.productActivationRequest,
      }),
    }),
    postApiV1Feature: build.mutation<
      PostApiV1FeatureApiResponse,
      PostApiV1FeatureApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/feature`,
        method: "POST",
        body: queryArg.featureListRequest,
      }),
    }),
    getApiV1HealthCheck: build.query<
      GetApiV1HealthCheckApiResponse,
      GetApiV1HealthCheckApiArg
    >({
      query: () => ({ url: `/api/v1/health-check` }),
    }),
    getApiV1BeHealthCheck: build.query<
      GetApiV1BeHealthCheckApiResponse,
      GetApiV1BeHealthCheckApiArg
    >({
      query: () => ({ url: `/api/v1/be-health-check` }),
    }),
    postApiV1ProductLicense: build.mutation<
      PostApiV1ProductLicenseApiResponse,
      PostApiV1ProductLicenseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/product-license`,
        method: "POST",
        body: queryArg.productLicenseRequest,
      }),
    }),
    putApiV1License: build.mutation<
      PutApiV1LicenseApiResponse,
      PutApiV1LicenseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/license`,
        method: "PUT",
        body: queryArg.updateLicenseRequest,
      }),
    }),
    getApiV1Timestamp: build.query<
      GetApiV1TimestampApiResponse,
      GetApiV1TimestampApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/timestamp`,
        body: queryArg.getCurrentTimestampRequest,
      }),
    }),
    postApiV1OrderList: build.mutation<
      PostApiV1OrderListApiResponse,
      PostApiV1OrderListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/order-list`,
        method: "POST",
        body: queryArg.searchOrdersRequest,
      }),
    }),
    getApiV1OrderByOrderId: build.query<
      GetApiV1OrderByOrderIdApiResponse,
      GetApiV1OrderByOrderIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/order/${queryArg.orderId}` }),
    }),
    getApiV1Product: build.query<
      GetApiV1ProductApiResponse,
      GetApiV1ProductApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/product`,
        params: { productId: queryArg.productId },
      }),
    }),
    postApiV1Product: build.mutation<
      PostApiV1ProductApiResponse,
      PostApiV1ProductApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/product`,
        method: "POST",
        body: queryArg.searchProductsRequest,
      }),
    }),
    postApiV1SerialNumberInternal: build.mutation<
      PostApiV1SerialNumberInternalApiResponse,
      PostApiV1SerialNumberInternalApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/serial-number/internal`,
        method: "POST",
        body: queryArg.createSToneInternalSerialNumberRequest,
      }),
    }),
    deleteApiV1SerialNumber: build.mutation<
      DeleteApiV1SerialNumberApiResponse,
      DeleteApiV1SerialNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/serial-number`,
        method: "DELETE",
        body: queryArg.deleteSerialNumberRequest,
      }),
    }),
    patchApiV1UserPassword: build.mutation<
      PatchApiV1UserPasswordApiResponse,
      PatchApiV1UserPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/password`,
        method: "PATCH",
        body: queryArg.resetUserPasswordRequest,
      }),
    }),
    deleteApiV1User: build.mutation<
      DeleteApiV1UserApiResponse,
      DeleteApiV1UserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user`,
        method: "DELETE",
        body: queryArg.deleteUserRequest,
      }),
    }),
    postApiV1User: build.mutation<
      PostApiV1UserApiResponse,
      PostApiV1UserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user`,
        method: "POST",
        body: queryArg.searchUsersRequest,
      }),
    }),
    putApiV1User: build.mutation<PutApiV1UserApiResponse, PutApiV1UserApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/user`,
        method: "PUT",
        body: queryArg.updateUserRequest,
      }),
    }),
    getApiV1UserMe: build.query<
      GetApiV1UserMeApiResponse,
      GetApiV1UserMeApiArg
    >({
      query: () => ({ url: `/api/v1/user/me` }),
    }),
    postApiV1Login: build.mutation<
      PostApiV1LoginApiResponse,
      PostApiV1LoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/login`,
        method: "POST",
        body: queryArg.loginRequest,
      }),
    }),
    postApiV1UserActivityLog: build.mutation<
      PostApiV1UserActivityLogApiResponse,
      PostApiV1UserActivityLogApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user-activity-log`,
        method: "POST",
        body: queryArg.searchUserActivityLogRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as cloudLicensesManagerApi };
export type PostApiV1StoneFakeActivationApiResponse =
  /** status 200 Success */ SToneFakeActivationResponseServiceResponse;
export type PostApiV1StoneFakeActivationApiArg = {
  sToneFakeActivationRequest: SToneFakeActivationRequest;
};
export type GetApiV1ActivationByActivationIdApiResponse =
  /** status 200 Success */ GetActivationResponseServiceResponse;
export type GetApiV1ActivationByActivationIdApiArg = {
  activationId: string;
};
export type PostApiV1ActivationListApiResponse =
  /** status 200 Success */ ActivationListResponseServiceResponse;
export type PostApiV1ActivationListApiArg = {
  activationListRequest: ActivationListRequest;
};
export type PostApiV1AddActivationRecordApiResponse =
  /** status 200 Success */ AddActivationRecordResponseServiceResponse;
export type PostApiV1AddActivationRecordApiArg = {
  addActivationRecordRequest: AddActivationRecordRequest;
};
export type PostApiV1FreeActivationApiResponse =
  /** status 200 Success */ FreeActivationResponseServiceResponse;
export type PostApiV1FreeActivationApiArg = {
  freeActivationRequest: FreeActivationRequest;
};
export type PostApiV1OfflineLicenseActivationApiResponse =
  /** status 200 Success */ OfflineLicenseActivationResponseServiceResponse;
export type PostApiV1OfflineLicenseActivationApiArg = {
  offlineLicenseActivationRequest: OfflineLicenseActivationRequest;
};
export type PostApiV1OfflinePlainLicenseActivationApiResponse =
  /** status 200 Success */ OfflineLicenseActivationResponseServiceResponse;
export type PostApiV1OfflinePlainLicenseActivationApiArg = {
  offlineLicenseActivationRequest: OfflineLicenseActivationRequest;
};
export type PostApiV1ProductActivationApiResponse =
  /** status 200 Success */ ProductActivationResponseServiceResponse;
export type PostApiV1ProductActivationApiArg = {
  productActivationRequest: ProductActivationRequest;
};
export type PostApiV1FeatureApiResponse =
  /** status 200 Success */ FeatureListResponseServiceResponse;
export type PostApiV1FeatureApiArg = {
  featureListRequest: FeatureListRequest;
};
export type GetApiV1HealthCheckApiResponse = unknown;
export type GetApiV1HealthCheckApiArg = void;
export type GetApiV1BeHealthCheckApiResponse =
  /** status 200 Success */ HealthCheckResponseServiceResponse;
export type GetApiV1BeHealthCheckApiArg = void;
export type PostApiV1ProductLicenseApiResponse =
  /** status 200 Success */ ProductLicenseResponseServiceResponse;
export type PostApiV1ProductLicenseApiArg = {
  productLicenseRequest: ProductLicenseRequest;
};
export type PutApiV1LicenseApiResponse =
  /** status 200 Success */ UpdateLicenseResponseServiceResponse;
export type PutApiV1LicenseApiArg = {
  updateLicenseRequest: UpdateLicenseRequest;
};
export type GetApiV1TimestampApiResponse =
  /** status 200 Success */ GetCurrentTimestampResponseServiceResponse;
export type GetApiV1TimestampApiArg = {
  getCurrentTimestampRequest: GetCurrentTimestampRequest;
};
export type PostApiV1OrderListApiResponse =
  /** status 200 Success */ SearchOrdersResponseServiceResponse;
export type PostApiV1OrderListApiArg = {
  searchOrdersRequest: SearchOrdersRequest;
};
export type GetApiV1OrderByOrderIdApiResponse =
  /** status 200 Success */ GetOrderResponseServiceResponse;
export type GetApiV1OrderByOrderIdApiArg = {
  orderId: string;
};
export type GetApiV1ProductApiResponse =
  /** status 200 Success */ GetProductResponseServiceResponse;
export type GetApiV1ProductApiArg = {
  productId?: string;
};
export type PostApiV1ProductApiResponse =
  /** status 200 Success */ SearchProductsResponseServiceResponse;
export type PostApiV1ProductApiArg = {
  searchProductsRequest: SearchProductsRequest;
};
export type PostApiV1SerialNumberInternalApiResponse =
  /** status 200 Success */ CreateSToneInternalSerialNumberResponseServiceResponse;
export type PostApiV1SerialNumberInternalApiArg = {
  createSToneInternalSerialNumberRequest: CreateSToneInternalSerialNumberRequest;
};
export type DeleteApiV1SerialNumberApiResponse =
  /** status 200 Success */ DeleteSerialNumberResponseServiceResponse;
export type DeleteApiV1SerialNumberApiArg = {
  deleteSerialNumberRequest: DeleteSerialNumberRequest;
};
export type PatchApiV1UserPasswordApiResponse =
  /** status 200 Success */ ResetUserPasswordResponseServiceResponse;
export type PatchApiV1UserPasswordApiArg = {
  resetUserPasswordRequest: ResetUserPasswordRequest;
};
export type DeleteApiV1UserApiResponse =
  /** status 200 Success */ DeleteUserResponseServiceResponse;
export type DeleteApiV1UserApiArg = {
  deleteUserRequest: DeleteUserRequest;
};
export type PostApiV1UserApiResponse =
  /** status 200 Success */ SearchUsersResponseServiceResponse;
export type PostApiV1UserApiArg = {
  searchUsersRequest: SearchUsersRequest;
};
export type PutApiV1UserApiResponse =
  /** status 200 Success */ UpdateUserResponseServiceResponse;
export type PutApiV1UserApiArg = {
  updateUserRequest: UpdateUserRequest;
};
export type GetApiV1UserMeApiResponse =
  /** status 200 Success */ GetUserResponseServiceResponse;
export type GetApiV1UserMeApiArg = void;
export type PostApiV1LoginApiResponse =
  /** status 200 Success */ LoginResponseServiceResponse;
export type PostApiV1LoginApiArg = {
  loginRequest: LoginRequest;
};
export type PostApiV1UserActivityLogApiResponse =
  /** status 200 Success */ SearchUserActivityLogResponseServiceResponse;
export type PostApiV1UserActivityLogApiArg = {
  searchUserActivityLogRequest: SearchUserActivityLogRequest;
};
export type SToneFakeActivationResponse = {
  jwtLicense?: string | null;
};
export type SToneFakeActivationResponseServiceResponse = {
  payload?: SToneFakeActivationResponse;
  error?: number | null;
};
export type SToneFakeActivationRequest = {
  license?: string | null;
  userName?: string | null;
  userSurname?: string | null;
  company?: string | null;
  address?: string | null;
  country?: string | null;
  email?: string | null;
  telephone?: string | null;
  fax?: string | null;
};
export type ActivationItem = {
  activationId: string;
  username?: string | null;
  serialNumber: string;
  hardwareId: string;
  orderId: string;
  company?: string | null;
  address?: string | null;
  country?: string | null;
  email?: string | null;
  telephone?: string | null;
  fax?: string | null;
  activationDate: number;
  lastCheckDate: number;
  signatures?: string[] | null;
  notes?: string | null;
};
export type ActivationFeatureItem = {
  featureId: string;
  name?: string | null;
  description?: string | null;
  expirationDate?: number | null;
  inserted?: number | null;
  updated?: number | null;
  enabled?: boolean;
};
export type PropData = {
  key?: string | null;
  value?: string | null;
};
export type GetActivationResponse = {
  activation?: ActivationItem;
  features?: ActivationFeatureItem[] | null;
  props?: PropData[] | null;
};
export type GetActivationResponseServiceResponse = {
  payload?: GetActivationResponse;
  error?: number | null;
};
export type ActivationListResponse = {
  activations?: ActivationItem[] | null;
  count?: number;
};
export type ActivationListResponseServiceResponse = {
  payload?: ActivationListResponse;
  error?: number | null;
};
export type ActivationListRequest = {
  page?: number | null;
  pageSize?: number | null;
  orderId?: string | null;
  search?: string | null;
};
export type AddActivationRecordResponse = object;
export type AddActivationRecordResponseServiceResponse = {
  payload?: AddActivationRecordResponse;
  error?: number | null;
};
export type AddActivationRecordRequest = {
  customerID?: string | null;
  customerCode?: string | null;
  referenceDate?: string | null;
  branchCode?: string | null;
  orderNumber?: string | null;
  productTypeName?: string | null;
  serialNumber?: string | null;
  referenceEmail?: string | null;
};
export type FreeActivationResponse = object;
export type FreeActivationResponseServiceResponse = {
  payload?: FreeActivationResponse;
  error?: number | null;
};
export type FreeActivationRequest = {
  activationId?: string | null;
};
export type OfflineLicenseActivationResponse = {
  jwtLicense?: string | null;
};
export type OfflineLicenseActivationResponseServiceResponse = {
  payload?: OfflineLicenseActivationResponse;
  error?: number | null;
};
export type OfflineLicenseActivationRequest = {
  userName?: string | null;
  userSurname?: string | null;
  company?: string | null;
  address?: string | null;
  country?: string | null;
  email?: string | null;
  telephone?: string | null;
  fax?: string | null;
  serialNumber?: string | null;
  serialNumbers?: string[] | null;
  orderId?: string | null;
  hardwareId?: string | null;
  expirationDate?: number | null;
};
export type ProductActivationResponse = {
  jwtLicense?: string | null;
};
export type ProductActivationResponseServiceResponse = {
  payload?: ProductActivationResponse;
  error?: number | null;
};
export type ProductActivationRequest = {
  serialNumber?: string | null;
  serialNumbers?: string[] | null;
  hardwareId?: string | null;
  userName?: string | null;
  userSurname?: string | null;
  company?: string | null;
  address?: string | null;
  country?: string | null;
  email?: string | null;
  telephone?: string | null;
  fax?: string | null;
};
export type FeatureData = {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  enabled?: boolean | null;
  inserted?: number | null;
  updated?: number | null;
};
export type FeatureListResponse = {
  features?: FeatureData[] | null;
};
export type FeatureListResponseServiceResponse = {
  payload?: FeatureListResponse;
  error?: number | null;
};
export type FeatureListRequest = {
  productId?: string | null;
  excludeFeatureIds?: string[] | null;
};
export type HealthCheckStatus = 10 | 50 | 90;
export type EnabledLogLevel = {
  error?: boolean;
  warning?: boolean;
  debug?: boolean;
  information?: boolean;
};
export type HealthCheckResponse = {
  dbConnection?: HealthCheckStatus;
  existMigrations?: HealthCheckStatus;
  existPrivateKey?: HealthCheckStatus;
  existAuthenticationPrivateKey?: HealthCheckStatus;
  existAuthenticationPublicKey?: HealthCheckStatus;
  existSTonePrivateKey?: HealthCheckStatus;
  existSTonePublicKey?: HealthCheckStatus;
  existAllowedDomainEmail?: HealthCheckStatus;
  enabledLogLevel?: EnabledLogLevel;
  storeSign?: string | null;
  sToneFakeActivationExpiration?: string | null;
};
export type HealthCheckResponseServiceResponse = {
  payload?: HealthCheckResponse;
  error?: number | null;
};
export type ProductLicenseResponse = {
  jwtLicense?: string | null;
};
export type ProductLicenseResponseServiceResponse = {
  payload?: ProductLicenseResponse;
  error?: number | null;
};
export type ProductLicenseRequest = {
  hardwareId?: string | null;
  serialNumber?: string | null;
  serialNumbers?: string[] | null;
};
export type UpdateLicenseResponse = object;
export type UpdateLicenseResponseServiceResponse = {
  payload?: UpdateLicenseResponse;
  error?: number | null;
};
export type UpdateLicenseRequest = {
  activationId?: string | null;
  signatures?: string[] | null;
  features?: ActivationFeatureItem[] | null;
  props?: PropData[] | null;
  notes?: string | null;
  hardwareId?: string | null;
};
export type GetCurrentTimestampDataResponse = {
  timestamp?: number;
};
export type GetCurrentTimestampResponse = {
  data?: GetCurrentTimestampDataResponse;
  sign?: string | null;
};
export type GetCurrentTimestampResponseServiceResponse = {
  payload?: GetCurrentTimestampResponse;
  error?: number | null;
};
export type GetCurrentTimestampRequest = object;
export type OrderItem = {
  orderId: string;
  orderNumber: string;
  customerId: string;
  customerCode: string;
  serialCount: number;
  usedSerialCount: number;
  referenceEmail?: string | null;
  referenceDate: number;
  carelBranch: string;
};
export type SearchOrdersResponse = {
  orders?: OrderItem[] | null;
  count?: number;
};
export type SearchOrdersResponseServiceResponse = {
  payload?: SearchOrdersResponse;
  error?: number | null;
};
export type SearchOrdersRequest = {
  page?: number | null;
  pageSize?: number | null;
  serialNumber?: string | null;
  orderNumber?: string | null;
  orderId?: string | null;
  search?: string | null;
};
export type GetOrderUnusedSerialNumber = {
  serialNumber: string;
  productName: string;
  isExpired: boolean;
  serialNumberId?: string | null;
};
export type GetOrderResponse = {
  order?: OrderItem;
  unusedSerialNumbers?: GetOrderUnusedSerialNumber[] | null;
};
export type GetOrderResponseServiceResponse = {
  payload?: GetOrderResponse;
  error?: number | null;
};
export type ProductData = {
  id: string;
  name?: string | null;
  productTypeName?: string | null;
  description?: string | null;
  appKey?: string | null;
  productReference?: string | null;
  serialNumberLife?: number;
  enabled: boolean;
  inserted: number;
  updated: number;
};
export type LicenseFeatureData = {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  enabled?: boolean | null;
  inserted?: number | null;
  updated?: number | null;
  expiredAt?: number | null;
};
export type GetProductResponse = {
  product?: ProductData;
  features?: LicenseFeatureData[] | null;
};
export type GetProductResponseServiceResponse = {
  payload?: GetProductResponse;
  error?: number | null;
};
export type SearchProductsResponse = {
  products?: ProductData[] | null;
  count?: number;
};
export type SearchProductsResponseServiceResponse = {
  payload?: SearchProductsResponse;
  error?: number | null;
};
export type SearchProductsRequest = {
  page?: number | null;
  pageSize?: number | null;
  search?: string | null;
};
export type CreateSToneInternalSerialNumberResponse = {
  serialNumberId?: string | null;
};
export type CreateSToneInternalSerialNumberResponseServiceResponse = {
  payload?: CreateSToneInternalSerialNumberResponse;
  error?: number | null;
};
export type CreateSToneInternalSerialNumberRequest = {
  productId?: string | null;
  returnSerialNumberId?: boolean;
};
export type DeleteSerialNumberResponse = object;
export type DeleteSerialNumberResponseServiceResponse = {
  payload?: DeleteSerialNumberResponse;
  error?: number | null;
};
export type DeleteSerialNumberRequest = {
  serialNumberId?: string | null;
};
export type ResetUserPasswordResponse = {
  password?: string | null;
};
export type ResetUserPasswordResponseServiceResponse = {
  payload?: ResetUserPasswordResponse;
  error?: number | null;
};
export type ResetUserPasswordRequest = {
  username?: string | null;
  privateKey?: string | null;
};
export type DeleteUserResponse = object;
export type DeleteUserResponseServiceResponse = {
  payload?: DeleteUserResponse;
  error?: number | null;
};
export type DeleteUserRequest = {
  userId?: string | null;
};
export type UserData = {
  id: string;
  username?: string | null;
  password?: string | null;
  role?: string | null;
  enabled: boolean;
  inserted: number;
  updated: number;
  updater?: string | null;
};
export type SearchUsersResponse = {
  users?: UserData[] | null;
  count?: number;
};
export type SearchUsersResponseServiceResponse = {
  payload?: SearchUsersResponse;
  error?: number | null;
};
export type SearchUsersRequest = {
  page?: number | null;
  pageSize?: number | null;
  search?: string | null;
};
export type UpdateUserResponse = {
  userId?: string | null;
};
export type UpdateUserResponseServiceResponse = {
  payload?: UpdateUserResponse;
  error?: number | null;
};
export type UpdateUserRequest = {
  userId?: string | null;
  username?: string | null;
  password?: string | null;
  role?: string | null;
  enabled?: boolean | null;
};
export type GetUserResponse = {
  username?: string | null;
  role?: string | null;
};
export type GetUserResponseServiceResponse = {
  payload?: GetUserResponse;
  error?: number | null;
};
export type LoginResponse = {
  token?: string | null;
  expiredAt?: number;
};
export type LoginResponseServiceResponse = {
  payload?: LoginResponse;
  error?: number | null;
};
export type LoginRequest = {
  username?: string | null;
  password?: string | null;
};
export type UserActivityLogType = 0 | 1 | 2 | 3;
export type UserActivityLogData = {
  id: string | null;
  userId?: string | null;
  operation?: UserActivityLogType;
  data?: string | null;
  inserted?: number | null;
};
export type SearchUserActivityLogResponse = {
  logs?: UserActivityLogData[] | null;
  users?: UserData[] | null;
  count?: number;
};
export type SearchUserActivityLogResponseServiceResponse = {
  payload?: SearchUserActivityLogResponse;
  error?: number | null;
};
export type SearchUserActivityLogRequest = {
  page?: number | null;
  pageSize?: number | null;
  operation?: UserActivityLogType;
  userId?: string | null;
};
export const {
  usePostApiV1StoneFakeActivationMutation,
  useGetApiV1ActivationByActivationIdQuery,
  usePostApiV1ActivationListMutation,
  usePostApiV1AddActivationRecordMutation,
  usePostApiV1FreeActivationMutation,
  usePostApiV1OfflineLicenseActivationMutation,
  usePostApiV1OfflinePlainLicenseActivationMutation,
  usePostApiV1ProductActivationMutation,
  usePostApiV1FeatureMutation,
  useGetApiV1HealthCheckQuery,
  useGetApiV1BeHealthCheckQuery,
  usePostApiV1ProductLicenseMutation,
  usePutApiV1LicenseMutation,
  useGetApiV1TimestampQuery,
  usePostApiV1OrderListMutation,
  useGetApiV1OrderByOrderIdQuery,
  useGetApiV1ProductQuery,
  usePostApiV1ProductMutation,
  usePostApiV1SerialNumberInternalMutation,
  useDeleteApiV1SerialNumberMutation,
  usePatchApiV1UserPasswordMutation,
  useDeleteApiV1UserMutation,
  usePostApiV1UserMutation,
  usePutApiV1UserMutation,
  useGetApiV1UserMeQuery,
  usePostApiV1LoginMutation,
  usePostApiV1UserActivityLogMutation,
} = injectedRtkApi;

export const {
  useLazyGetApiV1OrderByOrderIdQuery
} = injectedRtkApi;