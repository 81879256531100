export const AppRoute = {
    Order: "/order/:orderId",
    OffLineActivation: "/offline-activation/:orderId",
    Activation: "/activation/:orderId/:activationId",
    OrderList: "/order-list",
    ActivationList: "/activation-list",
    UsersManagement: "/users-management",
    Products: '/products',
    Product: "/product/:productId",
    Home: '/home',
    Login: '/',
    Credits: '/credits',
    UserActivityLog: '/user-activity-log',
};