import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, {FunctionComponent, memo} from "react";
import {useTranslation} from "react-i18next";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {DateUtils} from "../../../book/DateUtils";
import {useTheme} from "@mui/material";
import {UserRole, UserRoleUtils} from "../../../book/UserRoleUtils";
import {UserData} from "../../../api/CloudLicensesManagerApi";

const InternalUsersTable: FunctionComponent<UsersTableProps> = props => {
    const {t} = useTranslation();
    const theme = useTheme();

    return <Table aria-label={t("users")}>
        <TableHead>
            <TableRow>
                <TableCell>{t("Email")}</TableCell>
                <TableCell sx={{[theme.breakpoints.down('sm')]: {display: 'none'}}}>
                    {t("Role")}
                </TableCell>
                <TableCell sx={{[theme.breakpoints.down('lg')]: {display: 'none'}}}>
                    {t("Created")}
                </TableCell>
                <TableCell sx={{[theme.breakpoints.down('sm')]: {display: 'none'}}}>
                    {t("Last change")}
                </TableCell>
                <TableCell/>
            </TableRow>
        </TableHead>
        <TableBody>
            {props.users.map(user => (
                <TableRow key={user.id}>
                    <TableCell  scope="row">
                        {user.username}
                    </TableCell>
                    <TableCell sx={{[theme.breakpoints.down('sm')]: {display: 'none'}}}>
                        {t(user.role as string)}
                    </TableCell>
                    <TableCell sx={{[theme.breakpoints.down('lg')]: {display: 'none'}}}>
                        {DateUtils.dateTimeFormat(user.inserted)}
                    </TableCell>
                    <TableCell sx={{[theme.breakpoints.down('sm')]: {display: 'none'}}}>
                        {DateUtils.dateTimeFormat(user.updated)}
                    </TableCell>
                    <TableCell align="right">
                        <IconButton aria-label={t("Edit").toLowerCase()}
                                    title={t("Edit")}
                                    color="primary"
                                    onClick={_ => {
                                        props.onEdit(user);
                                    }}>
                            <EditIcon/>
                        </IconButton>
                        {UserRoleUtils.ToRole(user.role) !== UserRole.Admin &&
                            <IconButton aria-label={t("Remove").toLowerCase()}
                                        title={t("Remove")}
                                        color="primary"
                                        onClick={_ => {
                                            props.onDelete(user);
                                        }}>
                                <DeleteForeverIcon/>
                            </IconButton>}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>;
}

export const UsersTable = memo(InternalUsersTable, (prev, next) => {
    return !!prev && prev.users === next.users;
});

export interface UsersTableProps {
    onDelete: (user: UserData) => void;
    onEdit: (user: UserData) => void;
    users: UserData[];
}