import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {Provider} from "react-redux";
import {appStore, storePersistor} from "./store/AppStore";
import {createHashRouter, RouterProvider} from "react-router-dom";
import {AppRoute} from "./book/AppRoute";
import {Login} from "./component/page/login/Login";
import i18n from "./book/i18n";
import {I18nextProvider} from "react-i18next";
import {PersistGate} from "redux-persist/integration/react";
import {Loader} from "./component/common/loader/Loader";
import {OrderList} from "./component/page/order-list/OrderList";
import {OrderDetails} from "./component/page/order-details/OrderDetails";
import {ActivationList} from "./component/page/activation-list/ActivationList";
import {ActivationDetails} from "./component/page/activation-details/ActivationDetails";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {UsersManagement} from "./component/page/users-management/UsersManagement";
import {ProductList} from "./component/page/product-list/ProductList";
import {Credits} from "./component/page/credits/Credits";
import {OfflineActivation} from "./component/page/offline-activation/OfflineActivation";
import {ProductDetails} from "./component/page/product-details/ProductDetails";
import { UserActivityLog } from './component/page/user-activity-log/UserActivityLog';

const theme = createTheme({
    palette: {
        primary: {
            main: '#FE0000'
        },
        secondary: {
            main: '#FE0000'
        },
    },
});

const router = createHashRouter([
    {
        path: AppRoute.Login,
        element: <Login/>,
    },
    {
        path: AppRoute.Home,
        element: <OrderList/>,
    },
    {
        path: AppRoute.OrderList,
        element: <OrderList/>,
    },
    {
        path: AppRoute.Order,
        element: <OrderDetails/>,
    },
    {
        path: AppRoute.ActivationList,
        element: <ActivationList/>,
    },
    {
        path: AppRoute.Activation,
        element: <ActivationDetails/>,
    },
    {
        path: AppRoute.UsersManagement,
        element: <UsersManagement/>,
    },
    {
        path: AppRoute.Products,
        element: <ProductList/>,
    },
    {
        path: AppRoute.Product,
        element: <ProductDetails/>,
    },
    {
        path: AppRoute.UserActivityLog,
        element: <UserActivityLog/>,
    },
    {
        path: AppRoute.Credits,
        element: <Credits/>,
    },
    {
        path: AppRoute.OffLineActivation,
        element: <OfflineActivation/>,
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(<React.StrictMode>
    <ThemeProvider theme={theme}>
        <Provider store={appStore}>
            <Suspense fallback={<Loader/>}>
                <I18nextProvider i18n={i18n}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <PersistGate loading={null} persistor={storePersistor}>
                            <CssBaseline/>
                            <RouterProvider router={router}/>
                        </PersistGate>
                    </LocalizationProvider>
                </I18nextProvider>
            </Suspense>
        </Provider>
    </ThemeProvider>
</React.StrictMode>);
