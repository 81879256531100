import {FunctionComponent, useEffect, useState} from "react";
import {PageTemplate} from "../../common/page-template/PageTemplate";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    ActivationFeatureItem,
    PropData,
    useGetApiV1ActivationByActivationIdQuery,
    usePutApiV1LicenseMutation
} from "../../../api/CloudLicensesManagerApi";
import {Box, Button} from "@mui/material";
import {ActivationInfo} from "./ActivationInfo";
import {
    ProcessStatusNotification,
    toProcessStatus
} from "../../common/process-status-notification/ProcessStatusNotification";
import {ErrorMessage} from "../../common/error-message/ErrorMessage";
import {DottedListSkeleton} from "../../common/skeleton/DottedListSkeleton";
import {LicensePropsEditor} from "./LicensePropsEditor";
import {SignatureEditor} from "./SignatureEditor";
import {FeaturesEditor} from "./FeaturesEditor";
import {NotesEditor} from "./NotesEditor";
import {HardwareIdEditor} from "./HardwareIdEditor";

export const ActivationDetails: FunctionComponent = () => {
    const {activationId} = useParams();
    const {t} = useTranslation();
    const [state, setState] = useState<ActivationDetailsState>({
        signatures: [],
        features: [],
        licenseProps: [],
        propsErrors: false,
        notes: "",
        hardwareId: ""
    })
    const {
        data: activationData,
        isLoading: isActivationLoading,
        isError: isActivationError
    } = useGetApiV1ActivationByActivationIdQuery({
        activationId: activationId!
    });
    const [updateLicense, {
        data: updateLicenseData,
        isLoading: isUpdateLicenseLoading,
        error: updateLicenseError,
        reset: resetUpdateLicense
    }] = usePutApiV1LicenseMutation();

    useEffect(() => {
        setState(state => ({
            ...state,
            signatures: activationData?.payload?.activation?.signatures ?? [],
            features: activationData?.payload?.features ?? [],
            licenseProps: activationData?.payload?.props ?? [],
            notes: activationData?.payload?.activation?.notes ?? "",
            hardwareId: activationData?.payload?.activation?.hardwareId ?? ""
        }))
    }, [activationData?.payload?.activation])

    const ButtonsBox = () => (<Box sx={{overflow: 'hidden'}}>
        <Button variant="contained"
                color="primary"
                disabled={state.propsErrors}
                sx={{marginTop: '2em', float: 'right'}}
                onClick={() => {
                    updateLicense({
                        updateLicenseRequest: {
                            activationId: activationId,
                            features: state.features,
                            signatures: state.signatures,
                            props: state.licenseProps,
                            notes: state.notes,
                            hardwareId: state.hardwareId
                        }
                    })
                }}>
            {t("Save")}
        </Button>
    </Box>);

    return <PageTemplate pageTitle={`Activation - ${activationData?.payload?.activation?.activationId}`} showBackButton>
        {isActivationError && <ErrorMessage
            message={t("Error while load order, please check order id and retry.")}/>}

        {isActivationLoading && <DottedListSkeleton rowCount={7}/>}

        {Boolean(activationData?.payload?.activation) && <ActivationInfo activation={activationData?.payload?.activation!}/>}

        <ButtonsBox/>

        <Box sx={{marginTop: '2em'}}>
            <HardwareIdEditor value={(state.hardwareId ?? "")}
                              setValue={(newValue: string) => {
                                  setState({
                                      ...state,
                                      hardwareId: newValue
                                  });
                              }}/>

            <NotesEditor value={(state.notes ?? "")}
                         setValue={(newValues: string) => {
                            setState({
                                ...state,
                                notes: newValues
                            });
                         }}/>

            <LicensePropsEditor licenseProps={state.licenseProps}
                                onPropsChange={newLicenseProps => {
                                    setState(state => ({...state, licenseProps: newLicenseProps}));
                                }}
                                sx={{marginBottom: '2em'}}
                                onErrorChange={(error) => {
                                    setState(state => ({...state, propsErrors: error}));
                                }}/>

            <SignatureEditor values={(state.signatures ?? [])}
                             setValue={(newValues: string[]) => {
                                 setState({
                                     ...state,
                                     signatures: newValues
                                 });
                             }}/>

            <FeaturesEditor features={state.features}
                            isLoading={isActivationLoading}
                            onFeaturesChange={(features: ActivationFeatureItem[]) => {
                                setState({
                                    ...state,
                                    features: features
                                });
                            }}/>
        </Box>

        <ButtonsBox/>

        <ProcessStatusNotification
            status={toProcessStatus(isUpdateLicenseLoading, Boolean(updateLicenseData?.payload), Boolean(updateLicenseError))}
            onClose={() => resetUpdateLicense()}
            onLoadingMessage={t("Updating license please wait…")}
            onSuccessMessage={t("License updates successfully")}
            onErrorMessage={t("Updating license failed")}/>
    </PageTemplate>;
}

interface ActivationDetailsState {
    licenseProps: PropData[];
    features: ActivationFeatureItem[];
    signatures: string[];
    propsErrors: boolean;
    notes: string;
    hardwareId: string;
}