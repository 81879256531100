import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SearchInfo} from "../../common/search-info-grid/SearchInfo";
import {
    ActivationItem,
    useLazyGetApiV1OrderByOrderIdQuery,
    usePostApiV1ActivationListMutation,
    usePostApiV1FreeActivationMutation,
    usePostApiV1SerialNumberInternalMutation
} from "../../../api/CloudLicensesManagerApi";
import {Alert, FormControl, InputAdornment, InputLabel, OutlinedInput, Snackbar} from "@mui/material";
import {PageTemplate} from "../../common/page-template/PageTemplate";
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router-dom";
import {TableSkeleton} from "../../common/skeleton/TableSkeleton";
import SearchIcon from "@mui/icons-material/Search";
import DescriptionIcon from "@mui/icons-material/Description";
import {
    ProcessStatusNotification,
    toProcessStatus
} from "../../common/process-status-notification/ProcessStatusNotification";
import {ActivationsTable} from "../../common/activation-table/ActivationsTable";
import {OrderInfo} from "./OrderInfo";
import {FreeActivationDialog} from "../../common/free-activation-dialog/FreeActivationDialog";
import {ErrorMessage} from "../../common/error-message/ErrorMessage";
import {ActionButton} from "../../common/page-template/ActionButton";
import {AppRoute} from "../../../book/AppRoute";
import {DottedListSkeleton} from "../../common/skeleton/DottedListSkeleton";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import {Constants} from "../../../book/Constants";
import {CreateInternalSerialNumber} from "./CreateInternalSerialNumber";

export const OrderDetails: FunctionComponent = () => {
    const {orderId} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [state, setState] = useState<OrderDetailsState>({
        currentPage: 0,
        pageSize: 50,
        search: '',
        openCopiedToClipboardSnackbar: false,
        createSerialNumberDialogOpen: false
    });

    const [getOrder, {
        isLoading: isOrderLoading,
        isError: isOrderError,
        data: orderData
    }] = useLazyGetApiV1OrderByOrderIdQuery();

    const [searchActivations, {
        data: searchActivationsData,
        isLoading: isActivationsLoading,
        isError: isActivationsError
    }] = usePostApiV1ActivationListMutation();

    const [freeActivation, {
        data: freeActivationData,
        isLoading: isFreeActivationLoading,
        error: freeActivationError,
        reset: resetFreeActivation
    }] = usePostApiV1FreeActivationMutation();

    const [createSerialNumber, {
        data: createSerialNumberData,
        isLoading: isCreateSerialNumberLoading,
        isError: isCreateSerialNumberError,
        reset: resetCreateSerialNumber
    }] = usePostApiV1SerialNumberInternalMutation();

    const callSearchActivations = () => searchActivations({
        activationListRequest: {
            search: state.search.length ? state.search : null,
            page: state.currentPage,
            pageSize: state.pageSize,
            orderId: orderId
        }
    });

    useEffect(() => {
        getOrder({
            orderId: orderId as string
        })
    }, [orderId]);

    useEffect(() => {
        callSearchActivations();
    }, [state.search, state.currentPage, state.pageSize]);

    const searchResultActivations = searchActivationsData?.payload?.activations ?? [];
    const searchResultCount = searchActivationsData?.payload?.count ?? 0;
    const order = orderData?.payload?.order;
    const unusedSerialNumbers = orderData?.payload?.unusedSerialNumbers;

    const handleCloseCopiedToClipboardSnackbar = (event?: React.SyntheticEvent<any> | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setState(state => ({...state, openCopiedToClipboardSnackbar: false}));
    };

    const ActivationSearchInfoGrid = () => <SearchInfo
        count={searchResultCount}
        currentPage={state.currentPage}
        pageSize={state.pageSize}
        onPageChange={page => {
            setState(state => ({...state, currentPage: page}));
            callSearchActivations();
        }}
        onPageSizeChange={pageSize => {
            setState(state => ({...state, pageSize}));
            callSearchActivations();
        }}
        paginationLabel={t("Orders per page")}
        itemsLabel={t("orders")}/>;

    const pageActions = [<ActionButton label={t("Create offline license")}
                                       onClick={() => {
                                           navigate(AppRoute.OffLineActivation.replace(':orderId', orderId!))
                                       }}
                                       key="create-offline-license-action"
                                       icon={<DescriptionIcon/>}/>];

    if (orderId === Constants.stoneFakeActivationOrderId) {
        pageActions.push(<ActionButton label={t("Create serial number")}
                                       onClick={() => {
                                           setState(state => ({...state, createSerialNumberDialogOpen: true}));
                                       }}
                                       key="create-serial-number-action"
                                       icon={<AddShoppingCartIcon/>}/>);
    }

    return <PageTemplate pageTitle={`Order - ${isOrderLoading ? '---' : (order?.orderNumber ?? '')}`}
                         showBackButton
                         actions={pageActions}
                         backLabel={t("Back to order list")}>
        {isOrderError &&
            <ErrorMessage message={t("Error while load order, please check order id and retry.")}/>}

        {isOrderLoading &&
            <DottedListSkeleton rowCount={5}/>}

        {Boolean(order) &&
            <OrderInfo order={order!}
                       unusedSerialNumbers={unusedSerialNumbers ?? []}
                       onUnusedSerialNumberClick={() => {
                           setState(state => ({
                               ...state,
                               openCopiedToClipboardSnackbar: true
                           }));
                       }}/>}

        <Typography variant="h5" color="inherit" sx={{marginTop: '1.7em', marginBottom: '0.7em'}}>
            {t("Activations")}
        </Typography>

        <FormControl fullWidth variant="outlined">
            <InputLabel
                htmlFor="search-products">{t("Search by serial number or hardware ID…")}</InputLabel>
            <OutlinedInput value={state.search}
                           fullWidth
                           label={t("Search by order number, serial number, customer, or department…")}
                           sx={{marginBottom: '20px'}}
                           onChange={e => {
                               setState(state => ({...state, search: e.target.value}))
                           }}
                           endAdornment={<InputAdornment position="end"><SearchIcon/></InputAdornment>}/>
        </FormControl>

        {isActivationsError &&
            <Alert severity="error" sx={{marginTop: '40px'}}>
                {t("Error while loading activations. Please try again or contact the administrator")}
            </Alert>}

        {isActivationsLoading &&
            <TableSkeleton sx={{marginTop: '40px'}}
                           colCount={7}
                           rowCount={5}
                           showPagination/>}

        {Boolean(searchResultActivations) && <React.Fragment>
            <ActivationSearchInfoGrid/>

            <ActivationsTable activations={searchResultActivations}
                              onFreeActivationClick={(activation) => {
                                  setState(state => ({...state, activationToFree: activation}));
                              }}/>

            <ActivationSearchInfoGrid/>
        </React.Fragment>}

        <Snackbar open={state.openCopiedToClipboardSnackbar} autoHideDuration={4000}
                  onClose={handleCloseCopiedToClipboardSnackbar}>
            <Alert onClose={handleCloseCopiedToClipboardSnackbar} severity="success">
                Serial number copied to clipboard
            </Alert>
        </Snackbar>

        <ProcessStatusNotification
            status={toProcessStatus(isFreeActivationLoading, Boolean(freeActivationData?.payload), Boolean(freeActivationError))}
            onClose={() => resetFreeActivation()}
            onLoadingMessage={t("Freeing activation please wait…")}
            onSuccessMessage={t("Activation freed successfully")}
            onErrorMessage={t("Activation freeing failed")}/>

        <FreeActivationDialog open={Boolean(state.activationToFree)}
                              activation={state.activationToFree}
                              onCancelClick={() => {
                                  setState(state => ({
                                      ...state,
                                      activationToFree: undefined
                                  }));
                              }}
                              onOkClick={() => {
                                  const activationId = state.activationToFree?.activationId;

                                  setState(state => ({
                                      ...state,
                                      activationToFree: undefined
                                  }));
                                  freeActivation({
                                      freeActivationRequest: {
                                          activationId: activationId!
                                      }
                                  }).then(_=>{
                                      callSearchActivations();
                                      getOrder({
                                          orderId: orderId as string
                                      });
                                  });
                              }}/>

        <CreateInternalSerialNumber
            onCancelClick={() => {
                setState(state => ({...state, createSerialNumberDialogOpen: false}));
            }}
            onOkClick={(productId: string) => {
                setState(state => ({...state, createSerialNumberDialogOpen: false}));
                createSerialNumber({createSToneInternalSerialNumberRequest: {productId}});
                getOrder({
                    orderId: orderId as string
                });
            }}
            open={state.createSerialNumberDialogOpen}/>

        <ProcessStatusNotification
            status={toProcessStatus(isCreateSerialNumberLoading, Boolean(createSerialNumberData?.payload), Boolean(isCreateSerialNumberError))}
            onClose={() => resetCreateSerialNumber()}
            onLoadingMessage={t("Creating serial number please wait…")}
            onSuccessMessage={t("Creation serial number successfully")}
            onErrorMessage={t("Creation serial number failed")}/>
    </PageTemplate>
}

interface OrderDetailsState {
    currentPage: number;
    pageSize: number;
    search: string;
    openCopiedToClipboardSnackbar: boolean;
    activationToFree?: ActivationItem;
    createSerialNumberDialogOpen: boolean;
}